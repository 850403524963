import React, { useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import { Button, notification, Select } from 'antd';
import { UserContext } from '../../App';
import Table from '../../components/tables/DataTable';
import Sorting from '../../components/Sorting';
import { SERVER_URL } from '../../config';
import * as FileSaver from 'file-saver';
import { formatDateForTables } from '../../helpers/dates';

const { Option } = Select;

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'createdBy',
  'address',
  'zip',
  'gender',
  'phone',
  'hotMeal',
  'regres',
  'travelExpenses',
];

const Data = () => {
  const currentuser = useContext(UserContext);
  const [data, setData] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [sortOrder, setSortOrder] = useState(null);
  const [company, setCompany] = useState(undefined);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedSortingType, setSelectedSortingType] = useState('lastToFirstAdded');
  const [tableData, setTableData] = useState([]);
  const [newColumnKeys, setNewColumnKeys] = useState(undefined);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });

  /** Roles definition **/
  const allowedRoles =
    currentuser.data.role.includes('SuperAdmin') ||
    currentuser.data.role.includes('admin') ||
    currentuser.data.role.includes('user') ||
    currentuser.data.role.includes('hrOfficer') ||
    currentuser.data.role.includes('userOfficer');

  const allowedToAdmins =
    currentuser.data.role.includes('SuperAdmin') ||
    currentuser.data.role.includes('admin') ||
    currentuser.data.role.includes('user') ||
    currentuser.data.role.includes('userOfficer');
  /** end of roles definition **/

  useEffect(() => {
    if (allowedToAdmins) {
      let initialCompany = localStorage.getItem('selectedCompany')
        ? localStorage.getItem('selectedCompany')
        : currentuser?.data?.company?._id;
      setCompany(initialCompany);
      localStorage.setItem('selectedCompany', initialCompany);
    }
  }, []);

  useEffect(() => {
    allowedToAdmins && fetchCompanies();
  }, [allowedToAdmins]);

  useEffect(() => {
    !allowedToAdmins && fetchEmployees({ paginationObject: pagination });
  }, [allowedToAdmins]);

  useEffect(() => {
    company && fetchEmployees({ paginationObject: pagination });
  }, [company]);

  const fetchCompanies = async () => {
    try {
      const select = { _id: 1, name: 1 };
      const res = await Axios.get(
        `${SERVER_URL}/companies?select=${JSON.stringify(select)}&user=${currentuser.data.id}`,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );
      setCompanies(res.data.items);
    } catch (error) {
      console.error('error in fetchCompanies', error);
    }
  };

  const fetchEmployees = async (paginationObject, sorter = '', filtersForSearch) => {
    const { current = 1, pageSize = 20 } = paginationObject;
    let filter = company ? { company } : {};
    const select = {
      _id: 1,
      firstName: 1,
      lastName: 1,
      JMBG: 1,
      company: 1,
      email: 1,
      city: 1,
      employeeSector: 1,
      createdAt: 1,
      updatedAt: 1,
      endDate: 1,
      startDate: 1
    };

    const serializedSearchFilters =
      filtersForSearch && Object.keys(filtersForSearch).length > 0 ? JSON.stringify(filtersForSearch) : '';

    try {
      const res = await Axios.get(
        `${SERVER_URL}/data?filter=${JSON.stringify(filter)}&user=${currentuser.data.id}&company=${company}&page=${current}&pageSize=${pageSize}&searchFilters=${serializedSearchFilters}&select=${encodeURIComponent(JSON.stringify(select))}&sort=${sorter}`,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );
      const { total } = res.data;

      setPagination({ ...pagination, current, pageSize, total });

      setData(res);
    } catch (error) {
      console.log('error in fetching employees', error);
    }
  };

  const handleCompanyChange = async (value) => {
    localStorage.setItem('selectedCompany', value);
    setCompany(value);
  };
  //
  const deleteDataHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/data/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Employee is deleted.',
        placement: 'bottomRight',
      });
      await fetchEmployees({ paginationObject: pagination });
    } catch (err) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
      });
    }
  };
  //
  useEffect(() => {
    let columnKeys;
    if (data?.data?.items?.length > 0) {
      const keys = Object.keys(data.data.items[0]);
      columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
      columnKeys = ['numRow', 'firstName', 'lastName', 'JMBG', 'company', 'email', 'city', 'createdAt', 'updatedAt'];
      let alternativeNames = [];
      for (const columnKey of columnKeys) {
        if (columnKey === 'company') {
          alternativeNames.push('name of company');
        } else if (columnKey === 'numRow') {
          alternativeNames.push('no.');
        } else if (columnKey === 'firstName') {
          alternativeNames.push('Name');
        } else if (columnKey === 'lastName') {
          alternativeNames.push('Surname');
        } else if (columnKey === 'JMBG') {
          alternativeNames.push('personal number');
        } else if (columnKey === 'email') {
          alternativeNames.push(columnKey);
        } else if (columnKey === 'city') {
          alternativeNames.push('City');
        } else if (columnKey === 'createdAt') {
          alternativeNames.push('Created');
        } else if (columnKey === 'updatedAt') {
          alternativeNames.push('Updated');
        }
      }
      let keyArray = columnKeys;
      setNewColumnKeys(
        columnKeys.map((item, index) => ({
          originalName: keyArray[index],
          alternativeName: alternativeNames[index],
        })),
      );
    }
    let dataForTable;
    if (data?.data?.items?.length > 0) {
      dataForTable = data.data.items.map((item, index) => {
        item.numRow = index + 1;
        if (item?.company?.name) {
          item.company = item.company.name;
        }
        const formattedDate = formatDateForTables(item.createdAt);
        const formattedDate2 = formatDateForTables(item.updatedAt);
        return { ...item, createdAt: formattedDate, updatedAt: formattedDate2 };
      });

      const uniqueData = Array.from(new Map(dataForTable.map((item) => [item._id, item])).values());

      setTableData(uniqueData);
    }
  }, [data]);
  //
  const lockEmployees = () => {
    try {
      selectedRows.forEach(async (row) => {
        await Axios.put(
          `${SERVER_URL}/data/${row}`,
          { locked: true },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          },
        );
      });
      notification.success({
        message: 'Employees are locked.',
        placement: 'bottomRight',
      });
      setTimeout(() => {
        window.location.reload();
      }, 1200);
    } catch (error) {
      notification.error({
        message: 'Problem with lock. Please try later.',
        placement: 'bottomRight',
      });
    }
  };
  //
  const handleExport = async () => {
    const initialCompany = company ? company : localStorage.getItem('selectedCompany');
    if (currentuser.data.role.includes('hrOfficer') && !currentuser.data.role.includes('user')) {
      try {
        const response = await Axios.post(
          `${SERVER_URL}/excel-sector`,
          { company: initialCompany },
          { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
        );
        const chosenSector = await Axios.get(`${SERVER_URL}/sector/${initialCompany}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
        FileSaver.saveAs(SERVER_URL + response.data.path, `Employees - ${chosenSector.data.sectorName}.xlsx`);
        notification.success({
          message: 'Successful export of employees for sector.',
          placement: 'bottomRight',
        });
      } catch (err) {
        notification.error({
          message: 'Problem with download. Please try later.',
          placement: 'bottomRight',
        });
      }
    } else {
      try {
        const response = await Axios.post(
          `${SERVER_URL}/excel`,
          { company: initialCompany },
          { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
        );
        const chosenCompany = await Axios.get(`${SERVER_URL}/companies/${initialCompany}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
        FileSaver.saveAs(SERVER_URL + response.data.path, `Employees - ${chosenCompany.data.name}.xlsx`);
        notification.success({
          message: 'Successful export of employees.',
          placement: 'bottomRight',
        });
      } catch (err) {
        notification.error({
          message: 'Problem with download. Please try later.',
          placement: 'bottomRight',
        });
      }
    }
  };

  const changeCompanySortOrder = async (sorting) => {
    const initialCompany = company ? company : localStorage.getItem('selectedCompany');
    try {
      const response = await Axios.put(
        `${SERVER_URL}/companies/${initialCompany}`,
        {
          selectedSortingType: sorting,
        },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );

      return response.data.message;
    } catch (error) {
      console.log('error in changing company preferred sorting order of employees', error);
      notification.error({
        message: 'Problem with changing sorting type. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  const handleSortingChange = async (value) => {
    setSelectedSortingType(value);
    if (currentuser.data.role.includes('hrOfficer')) {
      await fetchEmployees({ paginationObject: pagination }, value);
    } else {
      const response = await changeCompanySortOrder(value);
      if (response === 'Updated') await fetchEmployees({ paginationObject: pagination });
    }
  };

  const handlePaginationChange = async (paginationObject, filters) => {
    setPagination((prev) => ({
      ...prev,
      current: paginationObject.current,
      pageSize: paginationObject.pageSize,
    }));

    await fetchEmployees(paginationObject, null, filters);
  };

  return (
    <div style={{ padding: '8px' }}>
      <div className='actions-block'>
        {allowedRoles && (
          <>
            <Link to='/admin/new-data'>
              <Button type='primary'>New employee</Button>
            </Link>
            {allowedToAdmins && (
              <Button
                onClick={lockEmployees}
                disabled={selectedRows.length === 0}
                type='primary'
                style={{ marginLeft: '10px' }}
              >
                Lock employees
              </Button>
            )}
          </>
        )}
        {allowedRoles && (
          <>
            {allowedToAdmins && (
              <Link to='/admin/unlock-employees'>
                <Button disabled={!localStorage.getItem('selectedCompany')} style={{ marginLeft: '10px' }}>
                  Unlock employees
                </Button>
              </Link>
            )}

            <Link to='/admin/terminate-employee'>
              <Button style={{ marginLeft: '10px' }} type='danger'>
                Terminate employment
              </Button>
            </Link>
            <Link to='/admin/extend-employment'>
              <Button className='extendButton' style={{ marginLeft: '10px' }}>
                Extend employment
              </Button>
            </Link>
            <Button style={{ marginLeft: '10px' }} onClick={handleExport}>
              Export
            </Button>
            {!currentuser.data.role.includes('employee') && !currentuser.data.role.includes('hrOfficer') && (
              <Link to='/admin/upload-employees'>
                <Button className='extendButton' style={{ marginLeft: '10px' }}>
                  Import
                </Button>
              </Link>
            )}
          </>
        )}
      </div>
      {allowedToAdmins && (
        <div>
          <p style={{ marginTop: '20px' }}>Choose company:</p>
          <Select
            allowClear={true}
            showSearch
            optionFilterProp='children'
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            onChange={(value) => handleCompanyChange(value)}
            style={{ width: '45%', marginBottom: '20px' }}
            value={company}
          >
            {companies?.length &&
              companies.map((item) => (
                <Option value={item._id} key={item._id}>
                  {item.name}
                </Option>
              ))}
          </Select>
        </div>
      )}

      <div style={{ textAlign: 'center' }}>
        {allowedRoles && company && data?.data?.items?.length > 0 && newColumnKeys ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }}
          >
            <div
              style={{
                maxWidth: '600px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div style={{ marginRight: '20px' }}>Sorting:</div>
              <Sorting
                handleSortingChange={handleSortingChange}
                selectedSortingType={selectedSortingType}
                currentuser={currentuser}
              />
            </div>
            <Table
              data={tableData}
              deleteHandler={deleteDataHandler}
              columnKeys={newColumnKeys}
              editPath='/admin/edit-data/'
              viewPath='/admin/view-data/'
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              companyId={company}
              sortOrder={sortOrder}
              handlePaginationChange={handlePaginationChange}
              pagination={{
                ...pagination,
                pageSize: pagination.pageSize || 20,
                showSizeChanger: false,
              }}
              setPagination={setPagination}
            />
          </div>
        ) : (
          (!currentuser.data.role.includes('SuperAdmin') || !currentuser.data.role.includes('admin')) &&
          data?.data?.items?.length > 0 &&
          newColumnKeys && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
              }}
            >
              <div
                style={{
                  maxWidth: '600px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div style={{ marginRight: '20px' }}>Sorting:</div>
                <Sorting
                  handleSortingChange={handleSortingChange}
                  selectedSortingType={selectedSortingType}
                  currentuser={currentuser}
                />
              </div>
              <Table
                data={tableData}
                deleteHandler={deleteDataHandler}
                columnKeys={newColumnKeys}
                editPath='/admin/edit-data/'
                viewPath='/admin/view-data/'
                companyId={company}
                sortOrder={sortOrder}
                handlePaginationChange={handlePaginationChange}
                pagination={{
                  ...pagination,
                  pageSize: pagination.pageSize || 20,
                  showSizeChanger: false,
                }}
                setPagination={setPagination}
              />
            </div>
          )
        )}
        {data?.data?.items?.length === 0 && <h2>NO DATA</h2>}
      </div>
    </div>
  );
};

export default Data;
