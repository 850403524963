import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Input, Popconfirm, Table, Tooltip, Checkbox } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined, SearchOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';

const CompanyTable = ({ data, deleteHandler, columnKeys, editPath, viewPath, type }) => {
  const user = useContext(UserContext);
    const [selectedCompany, setSelectedCompany] = useState(() => {
        const storedCompanyId = localStorage.getItem('selectedEInvoiceCompany');
        const companies = Array.isArray(user?.data?.company) ? user.data.company : [user?.data?.company].filter(Boolean);
        const companyIds = companies.length > 0 ? companies.map(item => item._id) : [];

        return companyIds.includes(storedCompanyId)
            ? storedCompanyId
            : companies.length > 0 ? companies[0]?._id : null;
    });

  let searchInput;
  const eInvoiceOfficerWithMultipleCompanies = user?.data?.company?.length > 1;

  const handleCheckboxChange = (e, id) => {
    setSelectedCompany(e.target.checked ? id : null);
    localStorage.setItem('selectedEInvoiceCompany', id);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size='small'
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex) ? record[dataIndex[0]][dataIndex[1]] : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const renderTextWithTooltip = (text) => {
    const maxLength = 50;
    if (text && text.length > maxLength) {
      return (
        <Tooltip title={text}>
          <span
            style={{
              display: 'inline-block',
              maxWidth: '150px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {text}
          </span>
        </Tooltip>
      );
    } else {
      return <span>{text}</span>;
    }
  };

  const columns = columnKeys?.map((item) => ({
    key: item.originalName,
    title: item?.alternativeName?.toUpperCase(),
    dataIndex: ['title', 'category'].includes(item.originalName)
      ? [item.originalName, user.language ? user.language.selected.code : '']
      : item.originalName,
    ...getColumnSearchProps(
      ['title', 'category'].includes(item.originalName)
        ? [item.originalName, user.language ? user.language.selected.code : '']
        : item.originalName,
    ),
    render: (text) => renderTextWithTooltip(text), // Use tooltip renderer for text columns
  }));

  // Define the custom column for 'companyAdmin'
  const companyAdminColumn = {
    title: 'Company Admins',
    dataIndex: 'companyAdmin',
    key: 'companyAdmin',
    ...getColumnSearchProps('companyAdmin'),
    render: (text) =>
      text && text.replace ? (
        <span
          dangerouslySetInnerHTML={{
            __html: text.replace(/, /g, '<br/>'),
          }}
        />
      ) : null,
  };

  // Insert the 'companyAdmin' column into the 6th position
  if (type !== 'buyers') columns?.splice(5, 0, companyAdminColumn);

  columns?.push({
    title: 'Options',
    render: (text, record) => {
        const customColor =
            !record.client && !record.eInvoice &&
            (!record.eInvoiceOfficer || record.eInvoiceOfficer.length === 0)
                ? 'red'
                : !record.client && record.eInvoice
                    ? 'blue'
                    : 'black';
      return (
        <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
          {(user.data.role.includes('admin') ||
            user.data.role.includes('SuperAdmin') ||
            user.data.role.includes('userOfficer') ||
            (user.data.role.length === 1 && user.data.role.includes('user')) ||
            user.data.role.includes('eInvoiceOfficer') ||
            window.location.pathname.includes('customers')) && (
            <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
              {window.location.pathname.includes('customer') ? (
                <Link to={editPath + record._id}>
                  <EditOutlined
                    title='Edit customer'
                    style={{ textDecoration: 'none', color: type !== 'buyers' ? `${customColor}` : 'black' }}
                  />
                </Link>
              ) : (
                <Link to={editPath + record._id}>
                  <EditOutlined
                    title={window.location.pathname.includes('customers') ? `Edit customer` : `Edit company`}
                    style={{ textDecoration: 'none', color: `${customColor}` }}
                  />
                </Link>
              )}
            </div>
          )}

          {viewPath && (
            <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
              <Link to={viewPath + record._id}>
                <EyeOutlined title='View company' style={{ textDecoration: 'none', color: `${customColor}` }} />
              </Link>
            </div>
          )}
          {window.location.pathname.includes('customer') && (
            <div style={{ margin: '2px', padding: '4px' }}>
              <Popconfirm
                disabled={record._id === user.data.id}
                placement='left'
                title='This will delete customer'
                onConfirm={() => {
                  deleteHandler(record._id);
                }}
                okText='Ok'
                cancelText='Cancel'
              >
                <DeleteOutlined title='Delete customer' />
              </Popconfirm>
            </div>
          )}
          {(user.data.role.includes('SuperAdmin') || user.data.role.includes('admin')) && (
            <div style={{ margin: '2px', padding: '4px' }}>
              <Popconfirm
                disabled={record._id === user.data.id}
                placement='left'
                title='This will delete company'
                onConfirm={() => {
                  deleteHandler(record._id);
                }}
                okText='Ok'
                cancelText='Cancel'
              >
                <DeleteOutlined title='Delete company' />
              </Popconfirm>
            </div>
          )}

          {user.data.role.includes('eInvoiceOfficer') && eInvoiceOfficerWithMultipleCompanies && (
            <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
              <Checkbox
                onChange={(e) => handleCheckboxChange(e, record._id)}
                checked={selectedCompany === record._id}
                title='Select company'
                style={{ color: `${customColor}` }}
              >
                Select
              </Checkbox>
            </div>
          )}
        </div>
      );
    },
  });

    const getRowClassName = (record) => {
        if (!record.client && (!record.eInvoiceOfficer || record.eInvoiceOfficer.length === 0)) {
            return 'red-text-row';
        }
        if (!record.client && record.eInvoiceOfficer) {
            return 'blue-text-row';
        }
        return '';
    };

  return (
    <div>
      <Table
        size='middle'
        bordered
        dataSource={data}
        columns={columns}
        rowKey='_id'
        // if table is for eInvoices Customers/buyers
        rowClassName={type !== 'buyers' ? getRowClassName : ''}
        pagination={{
          defaultPageSize: 20,
          position: 'bottom',
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          hideOnSinglePage: true,
        }}
      />
    </div>
  );
};

export default CompanyTable;
