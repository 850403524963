import React, { useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Button, Modal, notification, Spin, Tooltip } from 'antd';
import { UserContext } from '../../App';
import Table from '../../components/tables/InvoiceTable';
import { SERVER_URL } from '../../config';
import { formatEInvoiceDate } from '../../helpers/dates';
import { LoadingOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import EInvoiceFilters from '../../components/EInvoiceFilters';

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'content',
  'orderId',
  'userEmail',
  'user',
  'products',
  'documentType',
  'type',
  'userCompany',
  'uploadedFiles',
  'customerId',
  'customerType',
  'vats',
  'comment',
  'subtotal',
  'paymentReference',
  'customerReference',
  'supplierReference',
  'openBalance',
  'total',
  'discounts',
  'totalVatExcl',
  'totalDiscounts',
  'interestArrears',
  'prepaidAmount',
  'poNumber',
  'customerNumber',
  'salesInvoiceId',
  'currency',
  'paymentMethod',
  'pdf',
  'supplierAddress',
  'supplierVat',
  'supplierCity',
  'supplierCountryCode',
  'supplierCompanyNumber',
  'supplierPostalCode',
  'customer',
  'createdAt',
  'updatedAt',
  'customerName',
  'customerPostalAddress',
  'customerVat',
  'customerCompanyNumber',
  'documentCurrency',
  'customerZipCode',
  'customerCountry',
  'supplierBankAccount',
  'customerCity',
  'paymentID',
  'paymentMethod',
  'vatSelected',
  'invoiceId',
  'customerCountry',
  'supplierMail',
  'supplierReference',
  'supplierStreetName',
  'totalDiscounts',
  'comment',
  'products',
  'supplyDate',
  'documentId',
  'creationDate',
  'sendingDate',
  'taxPointDate',
  'invoicePeriodStartDate',
  'invoicePeriodEndDate',
  'contractDocumentReference',
  'additionalDocumentReference',
  'attachment',
  'paymentMeansCode',
  'taxTotalAmount',
  'archived',
  'archivedDate',
  'recovered',
  'unifiedPdf',
  'dueDate',
  'documentDate',
  'rejectOrStornoComment',
];

const IncomingInvoices = () => {
  const currentuser = useContext(UserContext);
  const [purchases, setPurchases] = useState(undefined);
  const [isLoadingModalVisible, setIsLoadingModalVisible] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });

  const [newColumnKeys, setNewColumnKeys] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [customWidth, setCustomWidth] = useState(100);
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [fetchingLoading, setFetchingLoading] = useState(true);

  const history = useHistory();

  const selectedEInvoiceCompanyId = (() => {
    const storedCompanyId = localStorage.getItem('selectedEInvoiceCompany');
    const companies = Array.isArray(currentuser?.data?.company)
      ? currentuser.data.company
      : [currentuser?.data?.company].filter(Boolean);
    const companyIds = companies.length > 0 ? companies.map((item) => item._id) : [];

    return companyIds.includes(storedCompanyId) ? storedCompanyId : companies.length > 0 ? companies[0]?._id : null;
  })();

  const companies = Array.isArray(currentuser?.data?.company)
    ? currentuser.data.company
    : [currentuser?.data?.company].filter(Boolean);
  const selectedEInvoiceCompany = companies.find((item) => item._id === selectedEInvoiceCompanyId);

  useEffect(() => {
    getPurchaseInvoices(pagination);
    // purchaseScript();
  }, []);

  const getPurchaseInvoices = async (paginationObject, isClearFilter, filter) => {
    const { current, pageSize } = paginationObject;

    const filters = {
      companyId: selectedEInvoiceCompanyId,
      page: current,
      pageSize,
      dateFrom: !isClearFilter ? dateFrom : '',
      dateTo: !isClearFilter ? dateTo : '',
      filter
    };

    try {
      const response = await Axios.get(`${SERVER_URL}/get-purchase`, {
        params: filters,
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      const { total } = response.data;

      setPurchases(response.data);
      setPagination({ ...paginationObject, total });
      setFetchingLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const deleteInvoice = async (id, status) => {
    try {
      if (status === 'Draft') {
        await Axios.delete(`${SERVER_URL}/delete-draft/${id}?filter=${JSON.stringify(selectedEInvoiceCompanyId)}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
      }
      notification.success({
        message: 'The invoice has been deleted.',
        placement: 'bottomRight',
      });
      history.push('/admin/accounts-payable');
    } catch (err) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  const purchaseScript = async () => {
    const filters = {
      dateFrom,
      dateTo,
    };
    try {
      setIsLoadingModalVisible(true);
      const company = selectedEInvoiceCompany;
      await Axios.post(`${SERVER_URL}/purchase-script`, {
        company,
        filters,
      });
      notification.success({
        message: 'Successfully sync. with SEF',
        placement: 'bottomRight',
      });
    } catch (error) {
      console.log('error in sync with SEF', error);
      notification.error({
        message: 'You are unauthorized according to SEF! Please, double check your API key.',
        placement: 'bottomRight',
      });
    } finally {
      setTimeout(() => {
        setIsLoadingModalVisible(false);
        getPurchaseInvoices(pagination);
      }, 1200);
    }
  };

  const archiveInvoice = async (id) => {
    try {
      await Axios.post(
        `${SERVER_URL}/archive-purchase/${id}?filter=${JSON.stringify(selectedEInvoiceCompanyId)}`,
        {},
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );
      notification.success({
        message: 'The invoice has been archived.',
        placement: 'bottomRight',
      });
    } catch (err) {
      notification.error({
        message: 'Problem with archiving. Please try later.',
        placement: 'bottomRight',
      });
    } finally {
      setTimeout(() => {
        getPurchaseInvoices(pagination);
      }, 700);
    }
  };

  const acceptInvoice = async (id, comment) => {
    try {
      await Axios.post(
        `${SERVER_URL}/accept-purchase/${id}?filter=${JSON.stringify(selectedEInvoiceCompanyId)}`,
        comment,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );
      notification.success({
        message: 'The invoice has been accepted.',
        placement: 'bottomRight',
      });
    } catch (err) {
      notification.error({
        message: 'Problem with accepting. Please try later.',
        placement: 'bottomRight',
      });
    } finally {
      setTimeout(() => {
        getPurchaseInvoices(pagination);
      }, 700);
    }
  };

  const rejectInvoice = async (id, comment) => {
    try {
      await Axios.post(
        `${SERVER_URL}/reject-purchase/${id}?filter=${JSON.stringify(selectedEInvoiceCompanyId)}`,
        comment,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      ).then((res) => {
        if (res.status === 200) {
          notification.success({
            message: 'The invoice has been rejected.',
            placement: 'bottomRight',
          });
          setTimeout(() => {
            getPurchaseInvoices(pagination);
          }, 1000);
        }
      });
    } catch (err) {
      notification.error({
        message: 'Problem with rejecting. Please try later.',
        placement: 'bottomRight',
      });
    }
  };
  const checkStatusPurchase = async (id, paginationObject) => {
    try {
      const statusData = await Axios.get(
        `${SERVER_URL}/purchase-status/${id}?filter=${JSON.stringify(selectedEInvoiceCompanyId)}`,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );

      return statusData.data.status;
    } catch (error) {
      notification.error({
        message: 'Problem with checking invoice status. Please try later.',
        placement: 'bottomRight',
      });
    } finally {
      // Update the pagination to retain the current page position
      setPagination(paginationObject);
    }
  };

  useEffect(() => {
    let columnKeys;
    let newColumnKeysForState;
    if (purchases?.data && purchases?.data?.length > 0) {
      const keys = Object.keys(purchases?.data[0]);
      columnKeys = keys?.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
      let alternativeNames = [];
      for (const columnKey of columnKeys) {
        if (columnKey === 'documentNumber') {
          alternativeNames.push('Invoice No');
        } else if (columnKey === 'purchaseInvoiceId') {
          alternativeNames.push('SEF No');
        } else if (columnKey === 'deliveryDate') {
          alternativeNames.push('Delivery Date');
        } else if (columnKey === 'paymentDate') {
          alternativeNames.push('Due Date');
        } else if (columnKey === 'supplierName') {
          alternativeNames.push('Supplier Name');
        } else if (columnKey === 'status') {
          alternativeNames.push('Status');
        }
      }

      let keyArray = columnKeys;
      newColumnKeysForState = columnKeys?.map((item, index) => ({
        originalName: keyArray[index],
        alternativeName: alternativeNames[index],
      }));

      setNewColumnKeys(newColumnKeysForState);
    }

    let tableDataForState = [];
    if (purchases?.data && purchases?.data?.length > 0) {
      tableDataForState = purchases?.data?.map((item) => {
        const formattedDate = formatEInvoiceDate(item.deliveryDate);
        const formattedDate2 = item.dueDate !== null ? formatEInvoiceDate(item.dueDate) : 'N/A';
        const formattedDate3 = item.paymentDate !== null ? formatEInvoiceDate(item.paymentDate) : 'N/A';
        if (item.status === '') item.status = ' ';
        return { ...item, deliveryDate: formattedDate, dueDate: formattedDate2, paymentDate: formattedDate3 };
      });
      setTableData(tableDataForState);
    }

    // Define the allowed status values
    const allowedStatusValues = ['Accepted', 'Rejected', 'Approved', 'Storno'];

    // Check if all objects have a valid status and set custom width of the Options column in table
    const allObjectsHaveValidStatus = tableData.every((item) => {
      return item.status && allowedStatusValues.includes(item.status);
    });

    setCustomWidth(allObjectsHaveValidStatus ? 100 : 240);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchases]);

  const handleClearFilter = async () => {
    const paginationObject = {
      current: 1,
      pageSize: 20,
      total: 0,
    };
    await getPurchaseInvoices(paginationObject, true);
  };

  return (
    <>
      <div style={{ padding: '8px' }}>
        <div
          style={{
            padding: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '5px',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
            <EInvoiceFilters
              onSubmit={purchaseScript}
              onClearFilter={handleClearFilter}
              dateFrom={dateFrom}
              setDateFrom={setDateFrom}
              dateTo={dateTo}
              setDateTo={setDateTo}
            />
            <Tooltip
              title='After 3 months, incoming E-Invoices are automatically transferred to the Archive section'
              color='#b1b1b1'
              placement='right'
              autoAdjustOverflow={true}
            >
              <QuestionCircleOutlined style={{ color: '#b1b1b1', marginLeft: '8px', marginBottom: '7px' }} />
            </Tooltip>
          </div>

          <Button onClick={purchaseScript} type='primary'>
            Sync with SEF
          </Button>
        </div>
          <Table
            columnKeys={newColumnKeys}
            dataSource={tableData}
            deleteHandler={deleteInvoice}
            archiveHandler={archiveInvoice}
            acceptHandler={acceptInvoice}
            rejectHandler={rejectInvoice}
            purchaseHandler={checkStatusPurchase}
            customWidth={customWidth}
            handlePaginationChange={getPurchaseInvoices}
            pagination={pagination}
            setPagination={setPagination}
            handleClearFilter={handleClearFilter}
          />
        {fetchingLoading &&
          <div style={{ display: 'flex', height: '60vh', justifyContent: 'center', alignItems: 'center' }}>
            <Spin style={{ color: '#2db1ab' }} indicator={<LoadingOutlined style={{ fontSize: 44 }} spin />} />
          </div>
        }

        <Modal visible={isLoadingModalVisible} maskClosable={false} closable={false} footer={null}>
          <div style={{ textAlign: 'center', fontSize: '1.05rem' }}>
            <p>Syncing Invoices with SEF.</p>
            <p>Please wait. This may take up to a minute depending on number of invoices.</p>
            <p>
              <Spin style={{ color: '#2db1ab' }} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
            </p>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default IncomingInvoices;
