import React, { useEffect, useContext, useState } from 'react';
import Axios from 'axios';
import { notification, Button, Spin, Modal, Tooltip } from 'antd';
import { UserContext } from '../../App';
import Table from '../../components/tables/InvoiceTable';
import { SERVER_URL } from '../../config';
import { Link } from 'react-router-dom';
import { formatEInvoiceDate, formatNumber } from '../../helpers/dates';
import { LoadingOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import EInvoiceFilters from '../../components/EInvoiceFilters.js';

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'content',
  'orderId',
  'userEmail',
  'type',
  'user',
  'products',
  'documentType',
  'userCompany',
  'uploadedFiles',
  'customerId',
  'dueDate',
  'customerType',
  'vats',
  'comment',
  'subtotal',
  'paymentReference',
  'customerReference',
  'supplierReference',
  'openBalance',
  'customerVat',
  'discounts',
  'totalVatExcl',
  'totalDiscounts',
  'interestArrears',
  'prepaidAmount',
  'poNumber',
  'customerNumber',
  'pdf',
  'purchaseInvoiceId',
  'invoiceId',
  'documentLanguage',
  'documentCurrency',
  'customerCompanyNumber',
  'customerPostalAddress',
  'customerCity',
  'customerZipCode',
  'customerCountry',
  'customerCounty',
  'createdAt',
  'updatedAt',
  'isForeignInvoice',
  'archived',
  'archivedDate',
  'recovered',
  'supplierAddress',
  'supplierCity',
  'supplierPostalCode',
  'supplierCountryCode',
  'supplierCompanyNumber',
  'supplierName',
  'supplierVat',
  'qrCode',
  'unifiedPdf',
  'documentDate',
  'includeLogoInInvoice',
  'rejectOrStornoComment',
];

const OutgoingInvoices = () => {
  const currentuser = useContext(UserContext);
  const [invoices, setInvoices] = useState([]);
  const [salesInvoicesFull, setSalesInvoicesFull] = useState([]);
  const [companyId, setCompanyId] = useState();

  const [tableData, setTableData] = useState([]);
  const [tableDataFullForSearch, setTableDataFullForSearch] = useState([]);
  const [newColumnKeys, setNewColumnKeys] = useState([]);
  const [isLoadingModalVisible, setIsLoadingModalVisible] = useState(false);
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });

  const selectedEInvoiceCompanyId = (() => {
    const storedCompanyId = localStorage.getItem('selectedEInvoiceCompany');
    const companies = Array.isArray(currentuser?.data?.company)
      ? currentuser.data.company
      : [currentuser?.data?.company].filter(Boolean);
    const companyIds = companies.length > 0 ? companies.map((item) => item._id) : [];

    return companyIds.includes(storedCompanyId) ? storedCompanyId : companies.length > 0 ? companies[0]?._id : null;
  })();

  const companies = Array.isArray(currentuser?.data?.company)
    ? currentuser.data.company
    : [currentuser?.data?.company].filter(Boolean);
  const selectedEInvoiceCompany = companies.find((item) => item._id === selectedEInvoiceCompanyId);

  const fetchAllInvoices = async (companyId, paginationObject, isClearFilter) => {
    const { current, pageSize } = paginationObject;

    const filters = {
      companyId: companyId,
      page: current,
      pageSize,
      dateFrom: !isClearFilter ? dateFrom : '',
      dateTo: !isClearFilter ? dateTo : '',
    };

    try {
      const response = await Axios.get(`${SERVER_URL}/get-sales`, {
        params: filters,
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      const { total, data } = response.data;

      setInvoices(data);
      setPagination({ ...paginationObject, total });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const salesScript = async () => {
    const company = selectedEInvoiceCompany;
    const filters = {
      dateFrom,
      dateTo,
    };
    try {
      setIsLoadingModalVisible(true);
      await Axios.post(`${SERVER_URL}/sales-script`, {
        company,
        filters,
      });
      notification.success({
        message: 'Successfully sync with SEF',
        placement: 'bottomRight',
      });
    } catch (error) {
      console.error('error in sync with SEF', error);
      notification.error({
        message: 'You are unauthorized according to SEF! Please, double check your API key.',
        placement: 'bottomRight',
      });
    } finally {
      setTimeout(() => {
        setIsLoadingModalVisible(false);
        fetchAllInvoices(company._id, pagination);
      }, 1200);
    }
  };

  const getSalesInvoicesFullForSearch = async (companyId, isClearFilter) => {
    const filters = {
      companyId: companyId,
      dateFrom: !isClearFilter ? dateFrom : '',
      dateTo: !isClearFilter ? dateTo : '',
    };

    try {
      const response = await Axios.get(`${SERVER_URL}/get-sales`, {
        params: filters,
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      setSalesInvoicesFull(response.data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const deleteInvoice = async (id, status, value) => {
    try {
      if (status === 'Draft') {
        await Axios.delete(`${SERVER_URL}/delete-draft/${id}?filter=${JSON.stringify(companyId)}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        }).then((r) => {
          notification.success({
            message: 'The invoice has been deleted.',
            placement: 'bottomRight',
          });
          fetchAllInvoices(companyId, pagination);
        });
      } else if (status === 'Sent') {
        await Axios.delete(`${SERVER_URL}/storn/${id}?filter=${JSON.stringify(companyId)}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
          data: {
            source: value,
          },
        }).then(async (r) => {
          if (r.data.message === 'Storno') {
            notification.success({
              message: 'The invoice has been storned.',
              placement: 'bottomRight',
            });
            await checkStatusSales(id);
            await fetchAllInvoices(companyId, pagination);
            await getSalesInvoicesFullForSearch(companyId);
          } else {
            notification.error({
              message: `Problem with storn operation. ${r.data.message}. Please try later.`,
              placement: 'bottomRight',
            });
          }
        });
      }
    } catch (err) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  const checkStatusSales = async (id) => {
    try {
      await Axios.get(`${SERVER_URL}/sales-status/${id}?filter=${JSON.stringify(companyId)}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Status checked!',
        placement: 'bottomRight',
      });
    } catch (error) {
      console.error('error', error);
      notification.error({
        message: 'Problem with checking invoice status. Please try later.',
        placement: 'bottomRight',
      });
    } finally {
      setTimeout(() => {
        fetchAllInvoices(companyId, pagination);
      }, 1200);
    }
  };

  const archiveInvoice = async (id) => {
    try {
      await Axios.post(
        `${SERVER_URL}/archive-sales/${id}?filter=${JSON.stringify(companyId)}`,
        {},
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );

      notification.success({
        message: 'The invoice has been archived.',
        placement: 'bottomRight',
      });
    } catch (err) {
      notification.error({
        message: 'Problem with archiving. Please try later.',
        placement: 'bottomRight',
      });
    } finally {
      setTimeout(() => {
        fetchAllInvoices(companyId, pagination);
      }, 700);
    }
  };

  useEffect(() => {
    if (currentuser && selectedEInvoiceCompanyId) {
      const companies = Array.isArray(currentuser?.data?.company)
        ? currentuser.data.company
        : [currentuser?.data?.company].filter(Boolean);
      const selectedCompany = companies.find((item) => item._id === selectedEInvoiceCompanyId);
      setCompanyId(selectedCompany ? selectedCompany._id : null);
    }
  }, [currentuser, selectedEInvoiceCompanyId]);

  useEffect(() => {
    if (companyId && pagination) {
      fetchAllInvoices(companyId, pagination);
      getSalesInvoicesFullForSearch(companyId);
    }
  }, [companyId]);

  useEffect(() => {
    if (invoices && invoices.length) {
      let columnKeys;
      let newColumnKeysForState;
      if (invoices && invoices?.length > 0) {
        const keys = Object.keys(invoices[0]);
        columnKeys = keys?.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
        let alternativeNames = [];
        for (const columnKey of columnKeys) {
          if (columnKey === 'salesInvoiceId') {
            alternativeNames.push('SEF number');
          } else if (columnKey === 'customerName') {
            alternativeNames.push('Customer Name');
          } else if (columnKey === 'status') {
            alternativeNames.push('Status');
          } else if (columnKey === 'supplyDate') {
            alternativeNames.push('Supply Date');
          } else if (columnKey === 'total') {
            alternativeNames.push('Total balance (RSD)');
          } else if (columnKey === 'documentNumber') {
            alternativeNames.push('Invoice number');
          }
        }

        let keyArray = columnKeys;
        newColumnKeysForState = columnKeys?.map((item, index) => ({
          originalName: keyArray[index],
          alternativeName: alternativeNames[index],
        }));

        setNewColumnKeys(newColumnKeysForState);
      }

      let tableDataForState = [];
      if (invoices && invoices.length > 0) {
        tableDataForState = invoices?.map((item) => {
          const formattedDate = formatEInvoiceDate(item.supplyDate);
          const formattedDate2 = formatEInvoiceDate(item.dueDate);
          const formattedNumber = formatNumber(item.total);

          return { ...item, supplyDate: formattedDate, dueDate: formattedDate2, total: formattedNumber };
        });

        setTableData(tableDataForState);
      }

      let tableDataFullForSearchForState = [];
      if (salesInvoicesFull && salesInvoicesFull.length > 0) {
        tableDataFullForSearchForState = salesInvoicesFull?.map((item) => {
          const formattedDate = formatEInvoiceDate(item.supplyDate);
          const formattedDate2 = item.dueDate !== null ? formatEInvoiceDate(item.dueDate) : 'N/A';
          const formattedNumber = formatNumber(item.total);
          return { ...item, supplyDate: formattedDate, dueDate: formattedDate2, total: formattedNumber };
        });
        setTableDataFullForSearch(tableDataFullForSearchForState);
      }
    }
  }, [invoices, salesInvoicesFull]);

  const handleClearFilter = async () => {
    const paginationObject = {
      current: 1,
      pageSize: 20,
      total: 0,
    };
    await fetchAllInvoices(companyId, paginationObject, true);
  };

  return (
    <>
      <div style={{ padding: '8px' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '10px',
          }}
        >
          <Link to='/admin/create-new-invoice'>
            <Button type='primary'>Create new invoice</Button>
          </Link>

          <div style={{ display: 'flex', alignItems: 'center', marginLeft: '20px', marginTop: '10px' }}>
            <EInvoiceFilters
              onSubmit={salesScript}
              onClearFilter={handleClearFilter}
              dateFrom={dateFrom}
              setDateFrom={setDateFrom}
              dateTo={dateTo}
              setDateTo={setDateTo}
            />
            <Tooltip
              title='After 3 months, outgoing E-Invoices are automatically transferred to the Archive section'
              color='#b1b1b1'
              placement='right'
              autoAdjustOverflow={true}
            >
              <QuestionCircleOutlined style={{ color: '#b1b1b1', marginLeft: '8px', marginBottom: '6px' }} />
            </Tooltip>
          </div>

          <Button onClick={() => salesScript()} type='primary' style={{ marginLeft: 'auto' }}>
            Sync with SEF
          </Button>
        </div>

        {invoices?.length &&
        tableData?.length &&
        tableDataFullForSearch?.length > 0 &&
        Object.keys(pagination)?.length ? (
          <Table
            type='outgoing'
            columnKeys={newColumnKeys}
            dataSource={tableData}
            dataForSearch={tableDataFullForSearch}
            deleteHandler={deleteInvoice}
            archiveHandler={archiveInvoice}
            salesHandler={checkStatusSales}
            customWidth={180}
            handlePaginationChange={fetchAllInvoices}
            pagination={pagination}
            setPagination={setPagination}
            companyId={companyId}
            handleClearFilter={handleClearFilter}
          />
        ) : null}

        <Modal visible={isLoadingModalVisible} maskClosable={false} closable={false} footer={null}>
          <div style={{ textAlign: 'center', fontSize: '1.05rem' }}>
            <p>Syncing Invoices with SEF.</p>
            <p>Please wait. This may take up to a minute depending on number of invoices.</p>
            <p>
              <Spin style={{ color: '#2db1ab' }} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
            </p>
          </div>
        </Modal>

        {!tableData || (tableData.length === 0 && <h2 style={{ textAlign: 'center', marginTop: '50px' }}>NO DATA</h2>)}
      </div>
    </>
  );
};

export default OutgoingInvoices;
