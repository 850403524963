import React, { useContext, useEffect, useState } from 'react';
import { Table, Input, Button, Tooltip } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import moment from 'moment';

const SalaryTable = ({
  dataSource,
  columnKeys,
  rowSelection,
  tableKey,
  handlePaginationChange,
  pagination,
  setPagination,
  handleClearFilter,
}) => {
  const [data, setData] = useState(dataSource);
  const [filteredColumnsStatus, setFilteredColumnsStatus] = useState({});
  const [searchInputs, setSearchInputs] = useState({});
  const user = useContext(UserContext);

  let searchInput;

  useEffect(() => {
    setData(dataSource);
  }, [dataSource, searchInputs]);

  const getColumnSearchProps = (dataIndex) =>
    dataIndex !== 'recorded' && {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        const currentSearchValue = searchInputs[dataIndex] || selectedKeys[0] || '';

        return (
          <div style={{ padding: 8 }}>
            <Input
              ref={(node) => {
                searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={currentSearchValue}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type='primary'
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              size='small'
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters, dataIndex)} size='small' style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        );
      },
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filteredColumnsStatus[dataIndex] ? 'red' : '#bbb', fontSize: '1rem' }} />
      ),
      onFilter: (value, record) => {
        const filterRecord = Array.isArray(dataIndex)
          ? dataIndex.reduce((acc, key) => (acc ? acc[key] : null), record)
          : record[dataIndex];
        if (!filterRecord || !value) return false;
        return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
      },
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => {
            searchInput?.select();
          });
        }
      },
    };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchInputs((prev) => ({ ...prev, [dataIndex]: selectedKeys[0] || '' }));
    setFilteredColumnsStatus((prev) => ({ ...prev, [dataIndex]: true }));
    handlePageChange(
      {
        current: 1,
        pageSize: pagination.pageSize,
      },
      { ...searchInputs, [dataIndex]: selectedKeys[0] || '' },
    );
  };

  const handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    setSearchInputs((prev) => {
      const newSearchInputs = { ...prev };
      delete newSearchInputs[dataIndex];
      return newSearchInputs;
    });

    setFilteredColumnsStatus((prev) => ({
      ...prev,
      [dataIndex]: false,
    }));

    handleClearFilter();
  };

  const handlePageChange = (current, filter) => {
      setPagination({
          current: pagination.current,
          pageSize: pagination.pageSize,
          total: pagination.total,
      });
      // setSearchFilters(filter);
    handlePaginationChange(current, filter);
  };

  const renderTextWithTooltip = (text) => {
    const maxLength = 30;
    if (text && text.length > maxLength) {
      return (
        <Tooltip title={text}>
          <span
            style={{
              display: 'inline-block',
              maxWidth: '150px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {text}
          </span>
        </Tooltip>
      );
    } else {
      return <span>{text}</span>;
    }
  };

  const getWidth = (itemOriginalName) => {
    switch (itemOriginalName) {
      case 'recorded':
        return '12%';
      case 'numRow':
        return '5%';
      case 'JMBG':
      case 'firstName':
      case 'lastName':
        return '14%';
      case 'updatedAtForShow':
        return '18%';
      default:
        return '0%';
    }
  };

  const columns = columnKeys?.map((item) => ({
    key: item.originalName,
    title: item.alternativeName.toUpperCase(),
    dataIndex: ['title', 'category'].includes(item.originalName)
      ? [item.originalName, user.language?.selected?.code || '']
      : item.originalName,
    ...getColumnSearchProps(
      ['title', 'category'].includes(item.originalName)
        ? [item.originalName, user.language?.selected?.code || '']
        : item.originalName,
    ),
    width: item.submittedIndicator === 'recorded' ? '12%' : getWidth(item.originalName),
    render:
      item.originalName === 'numRow'
        ? (_, __, index) => (pagination.current - 1) * pagination.pageSize + index + 1
        : (text) => renderTextWithTooltip(text),
  }));

  return (
    <div>
      <Table
        className='salary-table_element'
        size='middle'
        bordered
        dataSource={data}
        key={tableKey}
        columns={columns}
        rowKey='_id'
        rowSelection={{
          type: 'radio',
          ...rowSelection,
          columnTitle: 'Enter evidence',
        }}
        pagination={{
          // ...pagination,
          defaultPageSize: 15,
          position: 'bottom',
          showSizeChanger: false,
          pageSizeOptions: ['5', '10', '15', '20', '50'],
          hideOnSinglePage: true,
            current: pagination?.current || 1,
            pageSize: pagination.pageSize,
            total: pagination.total,
        }}
        onChange={(pagination, filters, sorter, extra) => {
            if (extra.action === 'paginate') {
                handlePageChange(pagination);
            }
        }}
        rowClassName={(record) => {
          const endDateFormatted = moment(record.endDate, ['DD-MM-YYYY', 'MM-DD-YYYY', 'YYYY-MM-DD']).format(
            'YYYY-MM-DD',
          );
          const currentDateFormatted = moment().format('YYYY-MM-DD');
          const isBefore = moment(endDateFormatted).isBefore(currentDateFormatted);
          return record.endDate && isBefore ? 'suspended' : '';
        }}
      />
    </div>
  );
};

export default SalaryTable;
