import React, { useState, useEffect, useContext } from 'react';
import Axios from 'axios';
import { Form, Input, Card, Row, Col, Select, Checkbox, Divider, Radio, notification } from 'antd';
import { toNumber } from 'lodash';
import { SERVER_URL } from '../../config';
import moment from 'moment';
import { UserContext } from '../../App.js';
const { Option } = Select;

export default function SalaryCalculator() {
  const [form] = Form.useForm();
  const [currency, setCurrency] = useState('RSD');
  const [currencyDisplay, setCurrencyDisplay] = useState('RSD');
  const [calculatorType, setCalculatorType] = useState('net');
  const [mealAllowance, setMealAllowance] = useState(false);
  const [mealAllowanceNeto, setMealAllowanceNeto] = useState(0);
  const [mealAllowanceGross, setMealAllowanceGross] = useState(0);
  const [holidayAllowance, setHolidayAllowance] = useState(false);
  const [holidayAllowanceNeto, setHolidayAllowanceNeto] = useState(0);
  const [holidayAllowanceGross, setHolidayAllowanceGross] = useState(0);
  const [incentiveCalculators, setIncentiveCalculators] = useState(false);
  const [incentive, setIncentive] = useState(false);
  const [incentive2, setIncentive2] = useState(false);
  const [neto, setNeto] = useState(0);
  const [totalGrossAmount, setTotalGrossAmount] = useState(0);
  const [baseForTax, setBaseForTax] = useState(0);
  const [taxSalary, setTaxSalary] = useState(0);
  const [contributionPDIEmployee, setContributionPDIEmployee] = useState(0);
  const [healthInsuranceEmployee, setHealthInsuranceEmployee] = useState(0);
  const [unemploymentInsuranceEmployee, setUnemploymentInsuranceEmployee] = useState(0);
  const [contributionPDIEmployer, setContributionPDIEmployer] = useState(0);
  const [healthInsuranceEmployer, setHealthInsuranceEmployer] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [currentDate, setCurrentDate] = useState('');
  const [usdDisplay, setUsdDisplay] = useState('');
  const [euroDisplay, setEuroDisplay] = useState('');
  const [gbpDisplay, setGbpDisplay] = useState('');
  const [rubDisplay, setRubDisplay] = useState('');
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [viewportHeight, setViewportHeight] = useState(false);
  const [viewportHeightValue, setViewportHeightValue] = useState(window.innerHeight);
  const [coefficient, setCoefficient] = useState(1);
  const [inputValue, setInputValue] = useState('');
  const [valueOfIncentives2, setValueOfIncentives2] = useState(0);
  const [totalGross2Incentives2, setTotalGross2Incentives2] = useState('');
  const [minimumValueForIncentive, setMinimumValueForIncentive] = useState(0);
  const [minimumValueForIncentive2, setMinimumValueForIncentive2] = useState(0);
  const [finalCurrencyCoefficient, setFinalCurrencyCoefficient] = useState(0);
  const [taxesWithoutIncentives, setTaxesWithoutIncentives] = useState(0);
  const [randomSelectKey, setRandomSelectKey] = useState(0);
  const [errorNetInput, setErrorNetInput] = useState(false);
  const [errorGross1Input, setErrorGross1Input] = useState(false);
  const [errorGross2Input, setErrorGross2Input] = useState(false);
  const [netInputLowestValue, setNetInputLowestValue] = useState(56672);
  const [gross1InputLowestValue, setGross1InputLowestValue] = useState(76789.82);
  const [gross2InputLowestValue, setGross2InputLowestValue] = useState(88423.48);
  const user = useContext(UserContext);

  let nonTaxableValue = 28423; //2025
  let minimumGrossValueForIncentive = 393855; // corrected value for 2025
  let minimumGrossValueForIncentive2 = 300000; // added value for 2025
  let maxBase = 656425; //2025
  let borderValue = 462996.23; //2025

  const disabledStyle = { backgroundColor: 'white', color: 'rgba(0, 0, 0, 0.85)' };

  useEffect(() => {
    // Update the state when the window is resized
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // set vertical scroll bar on the right column
  useEffect(() => {
    if (mealAllowance || holidayAllowance) {
      setViewportHeight(true);
    } else if ((incentive || incentive2) && totalGrossAmount * finalCurrencyCoefficient > minimumValueForIncentive) {
      setViewportHeight(true);
    } else if (incentive2 && calculatorType === 'gross2') {
      setViewportHeight(true);
    } else {
      setViewportHeight(false);
    }
  }, [
    mealAllowance,
    holidayAllowance,
    incentive,
    incentive2,
    totalGrossAmount,
    minimumValueForIncentive,
    finalCurrencyCoefficient,
  ]);

  function handleChangeMeal(e) {
    if (calculatorType === 'net') {
      setMealAllowanceNeto(e.target.value);
    }

    if (calculatorType === 'gross1') {
      setMealAllowanceGross(e.target.value);
    }
  }

  function handleChangeHoliday(e) {
    if (calculatorType === 'net') {
      setHolidayAllowanceNeto(e.target.value);
    }

    if (calculatorType === 'gross1') {
      setHolidayAllowanceGross(e.target.value);
    }
  }

  function handleChange(e) {
    setInputValue(e.target.value);
    if (calculatorType === 'net') setNeto(e.target.value);
    if (calculatorType === 'gross1') setTotalGrossAmount(e.target.value);
    if (calculatorType === 'gross2') setTotalAmount(e.target.value);
  }

  function handleCurrencyChange(value) {
    if (value === 'RSD') {
      setCurrency('RSD');
    } else if (value === 'EURO') {
      setCurrency('€');
    } else if (value === 'USD') {
      setCurrency('$');
    } else if (value === 'GBP') {
      setCurrency('£');
    } else if (value === 'RUB') {
      setCurrency('₽');
    }
    handleResetField();
  }

  function handleCurrencyDisplayChange(value) {
    if (value === 'RSD') {
      setCurrencyDisplay('RSD');
    } else if (value === 'EURO') {
      setCurrencyDisplay('€');
    } else if (value === 'USD') {
      setCurrencyDisplay('$');
    } else if (value === 'GBP') {
      setCurrencyDisplay('£');
    } else if (value === 'RUB') {
      setCurrencyDisplay('₽');
    }
    handleResetField();
  }

  // check calculation type for rub
  function handleCalculatorChange(value) {
    if (value === 'net') {
      setCalculatorType('net');
    } else if (value === 'gross1') {
      setCalculatorType('gross1');
    } else if (value === 'gross2') {
      setCalculatorType('gross2');
    }
    handleResetField();
  }

  function handleMealAllowance(value) {
    if (value === false) {
      setMealAllowance(false);
    } else {
      setMealAllowance(true);
    }
  }

  function handleHolidayAllowance(value) {
    if (value === false) {
      setHolidayAllowance(false);
    } else {
      setHolidayAllowance(true);
    }
  }

  const handleResetField = () => {
    setNeto(0);
    setTotalGrossAmount(0);
    setBaseForTax(0);
    setTaxSalary(0);
    setContributionPDIEmployee(0);
    setHealthInsuranceEmployee(0);
    setUnemploymentInsuranceEmployee(0);
    setContributionPDIEmployer(0);
    setHealthInsuranceEmployer(0);
    setTotalAmount(0);
    setHolidayAllowanceNeto(0);
    setHolidayAllowanceGross(0);
    setMealAllowanceNeto(0);
    setMealAllowanceGross(0);
    setValueOfIncentives2(0);
    setTotalGross2Incentives2(0);
    setTaxesWithoutIncentives(0);
    setErrorNetInput(false);
    setErrorGross1Input(false);
    setErrorGross2Input(false);
  };

  // set coefficient after click on Incentive for foreigner 1 checkbox
  // 100% is coefficient if not Incentive for foreigners
  // 30% is coefficient for Incentive for foreigner 1
  // meal and holiday allowance are not present if Incentive for foreigner 1 checkbox is true
  useEffect(() => {
    if (incentiveCalculators) {
      setMealAllowance(false);
      setHolidayAllowance(false);
    } else {
      setIncentive(false);
      setIncentive2(false);
    }
  }, [incentiveCalculators]);

  useEffect(() => {
    if (incentive) {
      // set coefficient for Incentive 1
      setCoefficient(0.3);
      setIncentive2(false);
      setMealAllowance(false);
      setHolidayAllowance(false);
    } else if (incentive2) {
      // set coefficient for Incentive 1 but it's not of use
      setCoefficient(1);
      setIncentive(false);
      setMealAllowance(false);
      setHolidayAllowance(false);
    } else {
      setCoefficient(1);
    }
  }, [incentive, incentive2]);

  // meal and holiday allowance are not present if calculatorType === 'gross2'
  useEffect(() => {
    if (calculatorType === 'gross2') {
      setMealAllowance(false);
      setHolidayAllowance(false);
    }
  }, [calculatorType]);

  // initial form fields reset
  useEffect(() => {
    handleResetField();
  }, []);

  useEffect(() => {
    let currencyCoefficient = 1;
    if (currencyDisplay === '€') {
      if (currency === '$') {
        nonTaxableValue = Number(nonTaxableValue / usdDisplay);
        minimumGrossValueForIncentive = Number(minimumGrossValueForIncentive / usdDisplay);
        minimumGrossValueForIncentive2 = Number(minimumGrossValueForIncentive2 / usdDisplay);
        maxBase = Number(maxBase / usdDisplay);
        borderValue = Number(borderValue / usdDisplay);
        setGross1InputLowestValue(Number(gross1InputLowestValue / usdDisplay));
        setNetInputLowestValue(Number(netInputLowestValue / usdDisplay));
        setGross2InputLowestValue(Number(gross2InputLowestValue / usdDisplay));
        currencyCoefficient = Number(usdDisplay / euroDisplay);
      }

      if (currency === '€') {
        nonTaxableValue = Number(nonTaxableValue / euroDisplay);
        minimumGrossValueForIncentive = Number(minimumGrossValueForIncentive / euroDisplay);
        minimumGrossValueForIncentive2 = Number(minimumGrossValueForIncentive2 / euroDisplay);
        maxBase = Number(maxBase / euroDisplay);
        borderValue = Number(borderValue / euroDisplay);
        setGross1InputLowestValue(Number(gross1InputLowestValue / euroDisplay));
        setNetInputLowestValue(Number(netInputLowestValue / euroDisplay));
        setGross2InputLowestValue(Number(gross2InputLowestValue / euroDisplay));
        currencyCoefficient = Number(1);
      }

      if (currency === '£') {
        nonTaxableValue = Number(nonTaxableValue / gbpDisplay);
        minimumGrossValueForIncentive = Number(minimumGrossValueForIncentive / gbpDisplay);
        minimumGrossValueForIncentive2 = Number(minimumGrossValueForIncentive2 / gbpDisplay);
        maxBase = Number(maxBase / gbpDisplay);
        borderValue = Number(borderValue / gbpDisplay);
        setGross1InputLowestValue(Number(gross1InputLowestValue / gbpDisplay));
        setNetInputLowestValue(Number(netInputLowestValue / gbpDisplay));
        setGross2InputLowestValue(Number(gross2InputLowestValue / gbpDisplay));
        currencyCoefficient = Number(gbpDisplay / euroDisplay);
      }

      if (currency === '₽') {
        nonTaxableValue = Number(nonTaxableValue / rubDisplay);
        minimumGrossValueForIncentive = Number(minimumGrossValueForIncentive / rubDisplay);
        minimumGrossValueForIncentive2 = Number(minimumGrossValueForIncentive2 / rubDisplay);
        maxBase = Number(maxBase / rubDisplay);
        borderValue = Number(borderValue / rubDisplay);
        setGross1InputLowestValue(Number(gross1InputLowestValue / rubDisplay));
        setNetInputLowestValue(Number(netInputLowestValue / rubDisplay));
        setGross2InputLowestValue(Number(gross2InputLowestValue / rubDisplay));
        currencyCoefficient = Number(rubDisplay / euroDisplay);
      }

      if (currency === 'RSD') {
        nonTaxableValue = Number(nonTaxableValue);
        minimumGrossValueForIncentive = Number(minimumGrossValueForIncentive);
        minimumGrossValueForIncentive2 = Number(minimumGrossValueForIncentive2);
        maxBase = Number(maxBase);
        borderValue = Number(borderValue);
        setGross1InputLowestValue(Number(gross1InputLowestValue));
        setNetInputLowestValue(Number(netInputLowestValue));
        setGross2InputLowestValue(Number(gross2InputLowestValue));
        currencyCoefficient = Number(1 / euroDisplay);
      }
    }

    if (currencyDisplay === '$') {
      if (currency === '€') {
        nonTaxableValue = Number(nonTaxableValue / euroDisplay);
        minimumGrossValueForIncentive = Number(minimumGrossValueForIncentive / euroDisplay);
        minimumGrossValueForIncentive2 = Number(minimumGrossValueForIncentive2 / euroDisplay);
        maxBase = Number(maxBase / euroDisplay);
        borderValue = Number(borderValue / euroDisplay);
        setGross1InputLowestValue(Number(gross1InputLowestValue / euroDisplay));
        setNetInputLowestValue(Number(netInputLowestValue / euroDisplay));
        setGross2InputLowestValue(Number(gross2InputLowestValue / euroDisplay));
        currencyCoefficient = Number(euroDisplay / usdDisplay);
      }

      if (currency === '$') {
        nonTaxableValue = Number(nonTaxableValue / usdDisplay);
        minimumGrossValueForIncentive = Number(minimumGrossValueForIncentive / usdDisplay);
        minimumGrossValueForIncentive2 = Number(minimumGrossValueForIncentive2 / usdDisplay);
        maxBase = Number(maxBase / usdDisplay);
        borderValue = Number(borderValue / usdDisplay);
        setGross1InputLowestValue(Number(gross1InputLowestValue / usdDisplay));
        setNetInputLowestValue(Number(netInputLowestValue / usdDisplay));
        setGross2InputLowestValue(Number(gross2InputLowestValue / usdDisplay));
        currencyCoefficient = Number(1);
      }

      if (currency === '£') {
        nonTaxableValue = Number(nonTaxableValue / gbpDisplay);
        minimumGrossValueForIncentive = Number(minimumGrossValueForIncentive / gbpDisplay);
        minimumGrossValueForIncentive2 = Number(minimumGrossValueForIncentive2 / gbpDisplay);
        maxBase = Number(maxBase / gbpDisplay);
        borderValue = Number(borderValue / gbpDisplay);
        setGross1InputLowestValue(Number(gross1InputLowestValue / gbpDisplay));
        setNetInputLowestValue(Number(netInputLowestValue / gbpDisplay));
        setGross2InputLowestValue(Number(gross2InputLowestValue / gbpDisplay));
        currencyCoefficient = Number(gbpDisplay / usdDisplay);
      }

      if (currency === '₽') {
        nonTaxableValue = Number(nonTaxableValue / rubDisplay);
        minimumGrossValueForIncentive = Number(minimumGrossValueForIncentive / rubDisplay);
        minimumGrossValueForIncentive2 = Number(minimumGrossValueForIncentive2 / rubDisplay);
        maxBase = Number(maxBase / rubDisplay);
        borderValue = Number(borderValue / rubDisplay);
        setGross1InputLowestValue(Number(gross1InputLowestValue / rubDisplay));
        setNetInputLowestValue(Number(netInputLowestValue / rubDisplay));
        setGross2InputLowestValue(Number(gross2InputLowestValue / rubDisplay));
        currencyCoefficient = Number(rubDisplay / usdDisplay);
      }

      if (currency === 'RSD') {
        nonTaxableValue = Number(nonTaxableValue);
        minimumGrossValueForIncentive = Number(minimumGrossValueForIncentive);
        minimumGrossValueForIncentive2 = Number(minimumGrossValueForIncentive2);
        maxBase = Number(maxBase);
        borderValue = Number(borderValue);
        setGross1InputLowestValue(Number(gross1InputLowestValue));
        setNetInputLowestValue(Number(netInputLowestValue));
        setGross2InputLowestValue(Number(gross2InputLowestValue));
        currencyCoefficient = Number(1 / usdDisplay);
      }
    }

    if (currencyDisplay === '£') {
      if (currency === '€') {
        nonTaxableValue = Number(nonTaxableValue / euroDisplay);
        minimumGrossValueForIncentive = Number(minimumGrossValueForIncentive / euroDisplay);
        minimumGrossValueForIncentive2 = Number(minimumGrossValueForIncentive2 / euroDisplay);
        maxBase = Number(maxBase / euroDisplay);
        borderValue = Number(borderValue / euroDisplay);
        setGross1InputLowestValue(Number(gross1InputLowestValue / euroDisplay));
        setNetInputLowestValue(Number(netInputLowestValue / euroDisplay));
        setGross2InputLowestValue(Number(gross2InputLowestValue / euroDisplay));
        currencyCoefficient = Number(euroDisplay / gbpDisplay);
      }

      if (currency === '$') {
        nonTaxableValue = Number(nonTaxableValue / usdDisplay);
        minimumGrossValueForIncentive = Number(minimumGrossValueForIncentive / usdDisplay);
        minimumGrossValueForIncentive2 = Number(minimumGrossValueForIncentive2 / usdDisplay);
        maxBase = Number(maxBase / usdDisplay);
        borderValue = Number(borderValue / usdDisplay);
        setGross1InputLowestValue(Number(gross1InputLowestValue / usdDisplay));
        setNetInputLowestValue(Number(netInputLowestValue / usdDisplay));
        setGross2InputLowestValue(Number(gross2InputLowestValue / usdDisplay));
        currencyCoefficient = Number(usdDisplay / gbpDisplay);
      }

      if (currency === '£') {
        nonTaxableValue = Number(nonTaxableValue / gbpDisplay);
        minimumGrossValueForIncentive = Number(minimumGrossValueForIncentive / gbpDisplay);
        minimumGrossValueForIncentive2 = Number(minimumGrossValueForIncentive2 / gbpDisplay);
        maxBase = Number(maxBase / gbpDisplay);
        borderValue = Number(borderValue / gbpDisplay);
        setGross1InputLowestValue(Number(gross1InputLowestValue / gbpDisplay));
        setNetInputLowestValue(Number(netInputLowestValue / gbpDisplay));
        setGross2InputLowestValue(Number(gross2InputLowestValue / gbpDisplay));
        currencyCoefficient = Number(1);
      }

      if (currency === '₽') {
        nonTaxableValue = Number(nonTaxableValue / rubDisplay);
        minimumGrossValueForIncentive = Number(minimumGrossValueForIncentive / rubDisplay);
        minimumGrossValueForIncentive2 = Number(minimumGrossValueForIncentive2 / rubDisplay);
        maxBase = Number(maxBase / rubDisplay);
        borderValue = Number(borderValue / rubDisplay);
        setGross1InputLowestValue(Number(gross1InputLowestValue / rubDisplay));
        setNetInputLowestValue(Number(netInputLowestValue / rubDisplay));
        setGross2InputLowestValue(Number(gross2InputLowestValue / rubDisplay));
        currencyCoefficient = Number(rubDisplay / gbpDisplay);
      }

      if (currency === 'RSD') {
        nonTaxableValue = Number(nonTaxableValue);
        minimumGrossValueForIncentive = Number(minimumGrossValueForIncentive);
        minimumGrossValueForIncentive2 = Number(minimumGrossValueForIncentive2);
        maxBase = Number(maxBase);
        borderValue = Number(borderValue);
        setGross1InputLowestValue(Number(gross1InputLowestValue));
        setNetInputLowestValue(Number(netInputLowestValue));
        setGross2InputLowestValue(Number(gross2InputLowestValue));
        currencyCoefficient = Number(1 / gbpDisplay);
      }
    }

    if (currencyDisplay === '₽') {
      if (currency === '€') {
        nonTaxableValue = Number(nonTaxableValue / euroDisplay);
        minimumGrossValueForIncentive = Number(minimumGrossValueForIncentive / euroDisplay);
        minimumGrossValueForIncentive2 = Number(minimumGrossValueForIncentive2 / euroDisplay);
        maxBase = Number(maxBase / euroDisplay);
        borderValue = Number(borderValue / euroDisplay);
        setGross1InputLowestValue(Number(gross1InputLowestValue / euroDisplay));
        setNetInputLowestValue(Number(netInputLowestValue / euroDisplay));
        setGross2InputLowestValue(Number(gross2InputLowestValue / euroDisplay));
        currencyCoefficient = Number(euroDisplay / rubDisplay);
      }

      if (currency === '₽') {
        nonTaxableValue = Number(nonTaxableValue / rubDisplay);
        minimumGrossValueForIncentive = Number(minimumGrossValueForIncentive / rubDisplay);
        minimumGrossValueForIncentive2 = Number(minimumGrossValueForIncentive2 / rubDisplay);
        maxBase = Number(maxBase / rubDisplay);
        borderValue = Number(borderValue / rubDisplay);
        setGross1InputLowestValue(Number(gross1InputLowestValue / rubDisplay));
        setNetInputLowestValue(Number(netInputLowestValue / rubDisplay));
        setGross2InputLowestValue(Number(gross2InputLowestValue / rubDisplay));
        currencyCoefficient = Number(1);
      }

      if (currency === '$') {
        nonTaxableValue = Number(nonTaxableValue / usdDisplay);
        minimumGrossValueForIncentive = Number(minimumGrossValueForIncentive / usdDisplay);
        minimumGrossValueForIncentive2 = Number(minimumGrossValueForIncentive2 / usdDisplay);
        maxBase = Number(maxBase / usdDisplay);
        borderValue = Number(borderValue / usdDisplay);
        setGross1InputLowestValue(Number(gross1InputLowestValue / usdDisplay));
        setNetInputLowestValue(Number(netInputLowestValue / usdDisplay));
        setGross2InputLowestValue(Number(gross2InputLowestValue / usdDisplay));
        currencyCoefficient = Number(usdDisplay / rubDisplay);
      }

      if (currency === '£') {
        nonTaxableValue = Number(nonTaxableValue / gbpDisplay);
        minimumGrossValueForIncentive = Number(minimumGrossValueForIncentive / gbpDisplay);
        minimumGrossValueForIncentive2 = Number(minimumGrossValueForIncentive2 / gbpDisplay);
        maxBase = Number(maxBase / gbpDisplay);
        borderValue = Number(borderValue / gbpDisplay);
        setGross1InputLowestValue(Number(gross1InputLowestValue / gbpDisplay));
        setNetInputLowestValue(Number(netInputLowestValue / gbpDisplay));
        setGross2InputLowestValue(Number(gross2InputLowestValue / gbpDisplay));
        currencyCoefficient = Number(gbpDisplay / rubDisplay);
      }

      if (currency === 'RSD') {
        nonTaxableValue = Number(nonTaxableValue);
        minimumGrossValueForIncentive = Number(minimumGrossValueForIncentive);
        minimumGrossValueForIncentive2 = Number(minimumGrossValueForIncentive2);
        maxBase = Number(maxBase);
        borderValue = Number(borderValue);
        setGross1InputLowestValue(Number(gross1InputLowestValue));
        setNetInputLowestValue(Number(netInputLowestValue));
        setGross2InputLowestValue(Number(gross2InputLowestValue));
        currencyCoefficient = Number(1 / rubDisplay);
      }
    }

    if (currencyDisplay === 'RSD') {
      if (currency === '€') {
        nonTaxableValue = Number(nonTaxableValue / euroDisplay);
        minimumGrossValueForIncentive = Number(minimumGrossValueForIncentive / euroDisplay);
        minimumGrossValueForIncentive2 = Number(minimumGrossValueForIncentive2 / euroDisplay);
        maxBase = Number(maxBase / euroDisplay);
        borderValue = Number(borderValue / euroDisplay);
        setGross1InputLowestValue(Number(gross1InputLowestValue / euroDisplay));
        setNetInputLowestValue(Number(netInputLowestValue / euroDisplay));
        setGross2InputLowestValue(Number(gross2InputLowestValue / euroDisplay));
        currencyCoefficient = Number(euroDisplay);
      }

      if (currency === 'RSD') {
        currencyCoefficient = Number(1);
      }

      if (currency === '$') {
        nonTaxableValue = Number(nonTaxableValue / usdDisplay);
        minimumGrossValueForIncentive = Number(minimumGrossValueForIncentive / usdDisplay);
        minimumGrossValueForIncentive2 = Number(minimumGrossValueForIncentive2 / usdDisplay);
        maxBase = Number(maxBase / usdDisplay);
        borderValue = Number(borderValue / usdDisplay);
        setNetInputLowestValue(Number(netInputLowestValue / usdDisplay));
        setGross1InputLowestValue(Number(gross1InputLowestValue / usdDisplay));
        setGross2InputLowestValue(Number(gross2InputLowestValue / usdDisplay));
        currencyCoefficient = Number(usdDisplay);
      }

      if (currency === '£') {
        nonTaxableValue = Number(nonTaxableValue / gbpDisplay);
        minimumGrossValueForIncentive = Number(minimumGrossValueForIncentive / gbpDisplay);
        minimumGrossValueForIncentive2 = Number(minimumGrossValueForIncentive2 / gbpDisplay);
        maxBase = Number(maxBase / gbpDisplay);
        borderValue = Number(borderValue / gbpDisplay);
        setNetInputLowestValue(Number(netInputLowestValue / gbpDisplay));
        setGross1InputLowestValue(Number(gross1InputLowestValue / gbpDisplay));
        setGross2InputLowestValue(Number(gross2InputLowestValue / gbpDisplay));
        currencyCoefficient = Number(gbpDisplay);
      }

      if (currency === '₽') {
        nonTaxableValue = Number(nonTaxableValue / rubDisplay);
        minimumGrossValueForIncentive = Number(minimumGrossValueForIncentive / rubDisplay);
        minimumGrossValueForIncentive2 = Number(minimumGrossValueForIncentive2 / rubDisplay);
        maxBase = Number(maxBase / rubDisplay);
        borderValue = Number(borderValue / rubDisplay);
        setNetInputLowestValue(Number(netInputLowestValue / rubDisplay));
        setGross1InputLowestValue(Number(gross1InputLowestValue / rubDisplay));
        setGross2InputLowestValue(Number(gross2InputLowestValue / rubDisplay));
        currencyCoefficient = Number(rubDisplay);
      }
    }

    setMinimumValueForIncentive(Number(minimumGrossValueForIncentive).toFixed(2));
    setMinimumValueForIncentive2(Number(minimumGrossValueForIncentive2).toFixed(2));
    setFinalCurrencyCoefficient(Number(currencyCoefficient));

    if (calculatorType === 'net' && !errorNetInput) {
      let totalGrossAmountCalculation;
      if (neto > borderValue) {
        totalGrossAmountCalculation = neto ? (neto - nonTaxableValue * 0.1 + 0.199 * maxBase) / 0.9 : 0;
      } else {
        totalGrossAmountCalculation = neto ? (neto - nonTaxableValue * 0.1) / 0.701 : 0;
      }
      setTotalGrossAmount(totalGrossAmountCalculation * currencyCoefficient);

      let baseForSocialSocialContribution =
          totalGrossAmount > 0
              ? totalGrossAmount / currencyCoefficient < maxBase
                  ? totalGrossAmount / currencyCoefficient
                  : maxBase
              : 0;
      baseForSocialSocialContribution = baseForSocialSocialContribution * currencyCoefficient;
      let baseForTaxCalculation = totalGrossAmountCalculation > 0 ? totalGrossAmountCalculation - nonTaxableValue : 0;
      setBaseForTax(baseForTaxCalculation * currencyCoefficient);
      setTaxSalary(baseForTaxCalculation * currencyCoefficient * 0.1);
      setContributionPDIEmployee(Number(baseForSocialSocialContribution) * 0.14);
      setHealthInsuranceEmployee(Number(baseForSocialSocialContribution) * 0.0515);
      setUnemploymentInsuranceEmployee(Number(baseForSocialSocialContribution) * 0.0075);
      setContributionPDIEmployer(Number(baseForSocialSocialContribution) * 0.1);
      setHealthInsuranceEmployer(Number(baseForSocialSocialContribution) * 0.0515);
      setTotalAmount(Number(totalGrossAmount + contributionPDIEmployer + healthInsuranceEmployer));
      if (totalGrossAmount / currencyCoefficient < maxBase) {
        setHolidayAllowanceGross(
            holidayAllowanceNeto ? toNumber(((holidayAllowanceNeto * currencyCoefficient) / 0.701).toFixed(2)) : 0,
        );
        setMealAllowanceGross(
            mealAllowanceNeto ? toNumber(((mealAllowanceNeto * currencyCoefficient) / 0.701).toFixed(2)) : 0,
        );
      } else {
        setHolidayAllowanceGross(
            holidayAllowanceNeto ? toNumber(((holidayAllowanceNeto * currencyCoefficient) / 0.9).toFixed(2)) : 0,
        );
        setMealAllowanceGross(
            mealAllowanceNeto ? toNumber(((mealAllowanceNeto * currencyCoefficient) / 0.9).toFixed(2)) : 0,
        );
      }

      // calculation for incentive1 calculator
      if (incentive) {
        const gross1Variable = neto > 0 ? (neto - 750) / 0.9103 : 0;
        setTotalGrossAmount(gross1Variable * currencyCoefficient);
        setTotalAmount(1.04545 * gross1Variable * currencyCoefficient);
        let baseForTaxCalculation = gross1Variable > 0 ? (gross1Variable - nonTaxableValue) * 0.3 : 0;
        setBaseForTax(baseForTaxCalculation * currencyCoefficient);
        setTaxSalary(baseForTaxCalculation * currencyCoefficient * 0.1);
        if (gross1Variable > 0) {
          if (gross1Variable * coefficient > maxBase) {
            baseForSocialSocialContribution = maxBase * currencyCoefficient;
          } else {
            baseForSocialSocialContribution = gross1Variable * coefficient * currencyCoefficient;
          }
        } else baseForSocialSocialContribution = 0;
        baseForSocialSocialContribution = baseForSocialSocialContribution * currencyCoefficient;
        setContributionPDIEmployee(Number(baseForSocialSocialContribution) * 0.14);
        setHealthInsuranceEmployee(Number(baseForSocialSocialContribution) * 0.0515);
        setUnemploymentInsuranceEmployee(Number(baseForSocialSocialContribution) * 0.0075);
        setContributionPDIEmployer(Number(baseForSocialSocialContribution) * 0.1);
        setHealthInsuranceEmployer(Number(baseForSocialSocialContribution) * 0.0515);
      }

      // calculation for incentive2 calculator
      if (incentive2) {
        setValueOfIncentives2(Number(taxSalary * 0.7 + (contributionPDIEmployee + contributionPDIEmployer)));
        setTotalGross2Incentives2(Number(totalAmount - valueOfIncentives2));
        setTaxesWithoutIncentives(Number(totalAmount - neto * currencyCoefficient));
      }
    }

    if (calculatorType === 'gross1' && !errorGross1Input) {
      let baseForSocialSocialContribution =
          totalGrossAmount > 0 ? (totalGrossAmount < maxBase ? totalGrossAmount * coefficient : maxBase) : 0;
      baseForSocialSocialContribution = baseForSocialSocialContribution * currencyCoefficient;
      let baseForTaxCalculation = totalGrossAmount > 0 ? (totalGrossAmount - nonTaxableValue) * coefficient : 0;
      setBaseForTax(baseForTaxCalculation * currencyCoefficient);
      setTaxSalary(baseForTaxCalculation * currencyCoefficient * 0.1);
      setContributionPDIEmployee(Number(baseForSocialSocialContribution) * 0.14);
      setHealthInsuranceEmployee(Number(baseForSocialSocialContribution) * 0.0515);
      setUnemploymentInsuranceEmployee(Number(baseForSocialSocialContribution) * 0.0075);
      setContributionPDIEmployer(Number(baseForSocialSocialContribution) * 0.1);
      setHealthInsuranceEmployer(Number(baseForSocialSocialContribution) * 0.0515);
      let netoCalculation;
      if (0.701 * totalGrossAmount + 0.1 * nonTaxableValue < borderValue) {
        netoCalculation = totalGrossAmount ? Number(0.701 * totalGrossAmount + 0.1 * nonTaxableValue) : 0;
      } else {
        netoCalculation = totalGrossAmount
            ? Number(0.9 * totalGrossAmount + 0.1 * nonTaxableValue - 0.199 * maxBase)
            : 0;
      }

      setNeto(netoCalculation * currencyCoefficient);

      let totalAmountCalculation =
          +totalGrossAmount +
          contributionPDIEmployer / currencyCoefficient +
          healthInsuranceEmployer / currencyCoefficient;
      setTotalAmount(totalAmountCalculation * currencyCoefficient);

      if (totalGrossAmount / currencyCoefficient < maxBase) {
        setHolidayAllowanceNeto(
            holidayAllowanceGross ? toNumber((holidayAllowanceGross * currencyCoefficient * 0.701).toFixed(2)) : 0,
        );
        setMealAllowanceNeto(
            mealAllowanceGross ? toNumber((mealAllowanceGross * currencyCoefficient * 0.701).toFixed(2)) : 0,
        );
      } else {
        setHolidayAllowanceNeto(
            holidayAllowanceGross ? toNumber((holidayAllowanceGross * currencyCoefficient * 0.9).toFixed(2)) : 0,
        );
        setMealAllowanceNeto(
            mealAllowanceGross ? toNumber((mealAllowanceGross * currencyCoefficient * 0.9).toFixed(2)) : 0,
        );
      }

      // calculation for incentive1 calculator
      if (incentive) {
        if (totalGrossAmount > 0) {
          if (totalGrossAmount * coefficient > maxBase) {
            baseForSocialSocialContribution = maxBase * currencyCoefficient;
          } else {
            baseForSocialSocialContribution = totalGrossAmount * coefficient * currencyCoefficient;
          }
        } else baseForSocialSocialContribution = 0;

        baseForTaxCalculation = totalGrossAmount > 0 ? (totalGrossAmount - nonTaxableValue) * coefficient : 0;
        setBaseForTax(baseForTaxCalculation * currencyCoefficient);
        setTaxSalary(baseForTaxCalculation * currencyCoefficient * 0.1);
        setContributionPDIEmployee(Number(baseForSocialSocialContribution) * 0.14);
        setHealthInsuranceEmployee(Number(baseForSocialSocialContribution) * 0.0515);
        setUnemploymentInsuranceEmployee(Number(baseForSocialSocialContribution) * 0.0075);
        setContributionPDIEmployer(Number(baseForSocialSocialContribution) * 0.1);
        setHealthInsuranceEmployer(Number(baseForSocialSocialContribution) * 0.0515);

        if (totalGrossAmount > 0) {
          if (totalGrossAmount * coefficient > maxBase) {
            netoCalculation =
                totalGrossAmount - baseForTaxCalculation * 0.1 - maxBase * 0.14 - maxBase * 0.0515 - maxBase * 0.0075;
          } else {
            netoCalculation =
                totalGrossAmount -
                baseForTaxCalculation * 0.1 -
                totalGrossAmount * coefficient * 0.14 -
                totalGrossAmount * coefficient * 0.0515 -
                totalGrossAmount * coefficient * 0.0075;
          }
        } else netoCalculation = 0;
        setNeto(Number(netoCalculation * currencyCoefficient).toFixed(2));
        const socialContibutionsTotal =
            Number(baseForSocialSocialContribution) * 0.14 +
            Number(baseForSocialSocialContribution) * 0.1 +
            Number(baseForSocialSocialContribution) * 0.0515 +
            Number(baseForSocialSocialContribution) * 0.0515 +
            Number(baseForSocialSocialContribution) * 0.0075;
        setTotalAmount(
            Number(
                netoCalculation * currencyCoefficient +
                baseForTaxCalculation * currencyCoefficient * 0.1 +
                socialContibutionsTotal,
            ).toFixed(2),
        );
      }

      // calculation for incentive2 calculator
      if (incentive2) {
        setValueOfIncentives2(Number(taxSalary * 0.7 + (contributionPDIEmployee + contributionPDIEmployer)));
        setTotalGross2Incentives2(Number(totalAmount - valueOfIncentives2));
        setTaxesWithoutIncentives(Number(totalAmount - neto));
      }
    }

    if (calculatorType === 'gross2' && !errorGross2Input) {
      let baseForSocialContribution;
      if (toNumber(totalAmount / 1.1515) < maxBase) {
        baseForSocialContribution = toNumber(toNumber(totalAmount / 1.1515) * coefficient).toFixed(2);
      } else {
        baseForSocialContribution = maxBase;
      }
      baseForSocialContribution = baseForSocialContribution * currencyCoefficient;

      setContributionPDIEmployer(toNumber((baseForSocialContribution * 0.1).toFixed(2)));
      setHealthInsuranceEmployer(toNumber((baseForSocialContribution * 0.0515).toFixed(2)));
      setContributionPDIEmployee(toNumber((baseForSocialContribution * 0.14).toFixed(2)));
      setHealthInsuranceEmployee(toNumber((baseForSocialContribution * 0.0515).toFixed(2)));
      setUnemploymentInsuranceEmployee(toNumber((baseForSocialContribution * 0.0075).toFixed(2)));

      const totalGrossAmountCalculation =
          +totalAmount - contributionPDIEmployer / currencyCoefficient - healthInsuranceEmployer / currencyCoefficient;
      setTotalGrossAmount(totalGrossAmountCalculation * currencyCoefficient);

      let baseForTaxCalculation =
          totalGrossAmount > 0 ? (totalGrossAmount / currencyCoefficient - nonTaxableValue) * coefficient : 0;
      baseForTaxCalculation = baseForTaxCalculation * currencyCoefficient;
      setBaseForTax(baseForTaxCalculation);
      setTaxSalary(baseForTaxCalculation * 0.1);

      let netoCalculation;
      if (0.701 * (totalGrossAmount / currencyCoefficient) + 0.1 * nonTaxableValue < borderValue) {
        netoCalculation = totalGrossAmount
            ? Number(0.701 * (totalGrossAmount / currencyCoefficient) + 0.1 * nonTaxableValue)
            : 0;
      } else {
        netoCalculation = totalGrossAmount
            ? Number(0.9 * (totalGrossAmount / currencyCoefficient) + 0.1 * nonTaxableValue - 0.199 * maxBase)
            : 0;
      }
      setNeto(netoCalculation * currencyCoefficient);

      // incentive 1 calculation
      if (incentive) {
        currencyCoefficient = currencyDisplay === 'RSD' ? 1 : currencyCoefficient;
        let gross1Value;
        let baseForSocialSocialContribution;
        if (totalAmount > 0) {
          if (((totalAmount / 1.04545)) * coefficient < maxBase) {
            gross1Value = totalAmount / 1.04545;
          } else {
            gross1Value = totalAmount - maxBase * 0.1 - maxBase * 0.0515;
          }
        } else gross1Value = 0;

        setTotalGrossAmount(Number(gross1Value * currencyCoefficient).toFixed(2));

        if (gross1Value * currencyCoefficient > 0) {
          if (gross1Value * currencyCoefficient * coefficient > maxBase) {
            baseForSocialSocialContribution = maxBase * currencyCoefficient;
          } else {
            baseForSocialSocialContribution = gross1Value * coefficient * currencyCoefficient;
          }
        } else baseForSocialSocialContribution = 0;

        baseForTaxCalculation = gross1Value > 0 ? (gross1Value - nonTaxableValue) * coefficient : 0;
        setBaseForTax(baseForTaxCalculation * currencyCoefficient);
        setTaxSalary(baseForTaxCalculation * currencyCoefficient * 0.1);
        setContributionPDIEmployee(Number(baseForSocialSocialContribution) * 0.14);
        setHealthInsuranceEmployee(Number(baseForSocialSocialContribution) * 0.0515);
        setUnemploymentInsuranceEmployee(Number(baseForSocialSocialContribution) * 0.0075);
        setContributionPDIEmployer(Number(baseForSocialSocialContribution) * 0.1);
        setHealthInsuranceEmployer(Number(baseForSocialSocialContribution) * 0.0515);

        if (gross1Value * currencyCoefficient > 0) {
          if (gross1Value * currencyCoefficient * coefficient > maxBase) {
            netoCalculation =
                gross1Value * currencyCoefficient -
                baseForTaxCalculation * currencyCoefficient * 0.1 -
                maxBase * 0.14 -
                maxBase * 0.0515 -
                maxBase * 0.0075;
          } else {
            netoCalculation =
                gross1Value * currencyCoefficient -
                baseForTaxCalculation * currencyCoefficient * 0.1 -
                gross1Value * currencyCoefficient * coefficient * 0.14 -
                gross1Value * currencyCoefficient * coefficient * 0.0515 -
                gross1Value * currencyCoefficient * coefficient * 0.0075;
          }
        } else netoCalculation = 0;
        setNeto(Number(netoCalculation).toFixed(2));
      }

      // calculation for incentive2 calculator
      if (incentive2) {
        setValueOfIncentives2(Number(taxSalary * 0.7 + (contributionPDIEmployee + contributionPDIEmployer)));
        setTotalGross2Incentives2(Number(totalAmount * currencyCoefficient - valueOfIncentives2));
        setTaxesWithoutIncentives(Number(totalAmount * currencyCoefficient - neto));
      }
    }
  }, [
    neto,
    totalGrossAmount,
    baseForTax,
    taxSalary,
    contributionPDIEmployee,
    contributionPDIEmployer,
    healthInsuranceEmployee,
    healthInsuranceEmployer,
    unemploymentInsuranceEmployee,
    totalAmount,
    mealAllowanceNeto,
    mealAllowanceGross,
    holidayAllowanceNeto,
    holidayAllowanceGross,
    totalGross2Incentives2,
    valueOfIncentives2,
    taxesWithoutIncentives,
    errorNetInput,
    errorGross1Input,
    errorGross2Input,
  ]);

  // this returns message to user when Incentives is turned on but the value is under border limit
  useEffect(() => {
    const checkConditions = () => {
      if (
          incentive &&
          totalGrossAmount &&
          totalGrossAmount > 0 &&
          totalGrossAmount < minimumValueForIncentive * (currencyDisplay !== 'RSD' ? finalCurrencyCoefficient : 1)
      ) {
        notification.info({
          message: `Value of TOTAL GROSS 1 value has to be higher than ${(minimumValueForIncentive * finalCurrencyCoefficient).toLocaleString()} ${currency} in order to use Incentive 1 calculators`,
          placement: 'bottomRight',
        });
        handleResetField();
      }

      if (
          incentive2 &&
          totalGrossAmount &&
          totalGrossAmount > 0 &&
          totalGrossAmount < minimumValueForIncentive2 * (currencyDisplay !== 'RSD' ? finalCurrencyCoefficient : 1)
      ) {
        notification.info({
          message: `Value of TOTAL GROSS 1 value has to be higher than ${minimumValueForIncentive2 * finalCurrencyCoefficient.toLocaleString()} ${currency} in order to use Incentive 2 calculators`,
          placement: 'bottomRight',
        });
        handleResetField();
      }
    };

    const timeout = setTimeout(() => {
      checkConditions();
    }, 1600);

    return () => clearTimeout(timeout);
  }, [
    inputValue,
    totalGrossAmount,
    incentive,
    incentive2,
    minimumGrossValueForIncentive,
    minimumGrossValueForIncentive2,
    minimumValueForIncentive,
  ]);

  useEffect(() => {
    async function convertUSDToRSD() {
      const response = await Axios.post(`${SERVER_URL}/currency`, { currency: 'usd' }, { withCredentials: false });
      setUsdDisplay(response.data.currentCurrency.exchange_middle);
    }
    async function convertEURToRSD() {
      const response = await Axios.post(`${SERVER_URL}/currency`, { currency: 'eur' }, { withCredentials: false });
      setEuroDisplay(response.data.currentCurrency.exchange_middle);
    }
    async function convertGBPToRSD() {
      const response = await Axios.post(`${SERVER_URL}/currency`, { currency: 'gbp' }, { withCredentials: false });
      setGbpDisplay(response.data.currentCurrency.exchange_middle);
    }
    async function convertRUBToRSD() {
      const response = await Axios.post(`${SERVER_URL}/currency`, { currency: 'rub' }, { withCredentials: false });
      setRubDisplay(response.data.currentCurrency.exchange_middle);
    }
    convertUSDToRSD();
    convertEURToRSD();
    convertGBPToRSD();
    convertRUBToRSD();
  }, []);

  useEffect(() => {
    setCurrentDate(moment().format('L'));
  }, []);

  const containerStyle = {
    width: '300px',
    height: viewportWidth < 1800 ? '650px' : '845px',
    overflowX: 'hidden',
    overflowY: 'hidden',
  };

  const contentStyle = {
    width: '100%',
    height: '100%',
    overflow: 'auto',
    overflowX: 'hidden',
    overflowY: !viewportHeight ? 'hidden' : 'scroll',
    padding: '10px',
  };

  return (
      <div className='dashboard calculator'>
        <div className='card-wrapper calculator'>
          <Card title='SALARY CALCULATOR' style={{ width: '100%', marginTop: '5px' }}>
            <Form
                labelWrap
                labelCol={{ span: 16 }}
                wrapperCol={{ span: 8 }}
                style={{
                  marginTop: viewportHeightValue > 820 ? '30px' : '15px',
                  marginBottom: viewportHeightValue > 820 ? '15px' : '30px',
                }}
                form={form}
            >
              <Row type='flex' style={{ justifyContent: 'space-between' }}>
                <Col span={10} className='calculator-left-col' style={{ height: '600px' }}>
                  <Form.Item labelCol={{ span: 7 }} label='Select Calculator' labelAlign='left'>
                    <Select
                        key={randomSelectKey}
                        style={{ width: 210, margin: '0 8px', border: '1px solid #2db1ab' }}
                        defaultValue={
                          !incentive || user?.data?.email === 'hlb.serv@gmail.com'
                              ? // ||
                                // user?.data?.email === 'racunovodstvo@tmconsulting.co.rs' ||
                                // user?.data?.email === 'borislav.z@tmconsulting.co.rs' ||
                                // user?.data?.email === 'hlb.serv+67287762387@gmail.com'
                              'net'
                              : 'gross1'
                        }
                        onChange={handleCalculatorChange}
                    >
                      {/** TODO delete this after implementation **/}
                      {(!incentive || user?.data?.email === 'hlb.serv@gmail.com') && (
                          // ||
                          // user?.data?.email === 'racunovodstvo@tmconsulting.co.rs' ||
                          // user?.data?.email === 'borislav.z@tmconsulting.co.rs' ||
                          // user?.data?.email === 'hlb.serv+67287762387@gmail.com'
                          <Option value='net'>Net to gross 1 & gross 2</Option>
                      )}
                      <Option value='gross1'>Gross 1 to net & gross 2</Option>
                      <Option value='gross2'>Gross 2 to net & gross 1</Option>*
                    </Select>
                  </Form.Item>

                  <Form.Item labelCol={{ span: 7 }} label='Enter value in' labelAlign='left'>
                    <Select style={{ width: 80, margin: '0 8px' }} defaultValue='RSD' onChange={handleCurrencyChange}>
                      <Option value='RSD'>RSD</Option>
                      <Option value='EURO'>EUR</Option>
                      <Option value='USD'>USD</Option>
                      <Option value='GBP'>GBP</Option>
                      <Option value='RUB'>RUB</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item labelCol={{ span: 7 }} label='Display results in' labelAlign='left'>
                    <Select
                        style={{ width: 80, margin: '0 8px' }}
                        defaultValue='RSD'
                        onChange={handleCurrencyDisplayChange}
                    >
                      <Option value='RSD'>RSD</Option>
                      <Option value='EURO'>EUR</Option>
                      <Option value='USD'>USD</Option>
                      <Option value='GBP'>GBP</Option>
                      <Option value='RUB'>RUB</Option>
                    </Select>
                  </Form.Item>
                  {calculatorType === 'gross2' || incentiveCalculators ? (
                      ''
                  ) : (
                      <>
                        <Form.Item labelCol={{ span: 7 }} label='Meal allowance' labelAlign='left'>
                          <Select
                              style={{ width: 80, margin: '0 8px' }}
                              defaultValue={false}
                              onChange={handleMealAllowance}
                          >
                            <Option value={true}>YES</Option>
                            <Option value={false}>NO</Option>
                          </Select>
                        </Form.Item>
                        <Form.Item labelCol={{ span: 7 }} label='Holiday allowance' labelAlign='left'>
                          <Select
                              style={{ width: 80, margin: '0 8px' }}
                              defaultValue={false}
                              onChange={handleHolidayAllowance}
                          >
                            <Option value={true}>YES</Option>
                            <Option value={false}>NO</Option>
                          </Select>
                        </Form.Item>
                      </>
                  )}

                  <Form.Item
                      valuePropName='checked'
                      label='INCENTIVE CALCULATORS'
                      name='incentiveCalculators'
                      className='bold-label calculator'
                      labelCol={{ span: viewportWidth > 1800 ? 9 : 12 }}
                      labelAlign='left'
                      style={{
                        marginBottom: viewportHeightValue < 820 ? '10px' : '',
                        marginTop: viewportHeightValue < 820 ? '10px' : '',
                      }}
                  >
                    <Row gutter={15} style={{ alignItems: 'center' }}>
                      <Col span={22}>
                        <Checkbox
                            style={{ marginLeft: viewportHeightValue < 820 ? '-25px' : '-30px' }}
                            value={incentive}
                            onChange={(e) => {
                              setIncentiveCalculators(e.target.checked);
                              handleResetField();
                            }}
                        />
                      </Col>
                    </Row>
                  </Form.Item>

                  {incentiveCalculators && (
                      <div style={{ marginTop: viewportHeightValue > 820 ? '-20px' : '-10px', marginLeft: '20px' }}>
                        <Row gutter={15} style={{ alignItems: 'center' }}>
                          <Col span={24}>
                            INCENTIVE FOR FOREIGNER 1:
                            <Radio.Group
                                onChange={(e) => {
                                  setIncentive(e.target.value === 'incentive' ? true : null);
                                  setIncentive2(e.target.value === 'incentive2' ? true : null);
                                  setRandomSelectKey(Math.random());
                                  {
                                    /** TODO delete this after implementation **/
                                  }
                                  if (
                                      e.target.value === 'incentive' &&
                                      !(
                                          (user?.data?.email === 'hlb.serv@gmail.com')
                                          // ||
                                          // user?.data?.email === 'racunovodstvo@tmconsulting.co.rs' ||
                                          // user?.data?.email === 'borislav.z@tmconsulting.co.rs' ||
                                          // user?.data?.email === 'hlb.serv+67287762387@gmail.com'
                                      )
                                  ) {
                                    setCalculatorType('gross1');
                                  }
                                  handleResetField();
                                }}
                                value={incentive ? 'incentive' : incentive2 ? 'incentive2' : null}
                            >
                              <Radio style={{ marginLeft: '20px' }} value='incentive'></Radio>
                            </Radio.Group>
                          </Col>
                        </Row>

                        <Row gutter={15} style={{ alignItems: 'center', marginTop: '10px' }}>
                          <Col span={24}>
                            INCENTIVE FOR FOREIGNER 2:
                            <Radio.Group
                                onChange={(e) => {
                                  setIncentive(e.target.value === 'incentive' ? true : null);
                                  setIncentive2(e.target.value === 'incentive2' ? true : null);
                                  handleResetField();
                                }}
                                value={incentive ? 'incentive' : incentive2 ? 'incentive2' : null}
                            >
                              <Radio style={{ marginLeft: '20px' }} value='incentive2'></Radio>
                            </Radio.Group>
                          </Col>
                        </Row>
                        <div style={{ marginTop: '7px' }}>
                          *** incentives 1 apply just for GROSS 1 salaries higher than &nbsp;
                          {(
                              minimumGrossValueForIncentive /
                              (currency === '€'
                                  ? euroDisplay
                                  : currency === '$'
                                      ? usdDisplay
                                      : currency === '£'
                                          ? gbpDisplay
                                          : currency === '₽'
                                              ? rubDisplay
                                              : 1)
                          ).toLocaleString()}
                          &nbsp; {currency}
                        </div>
                        <div style={{ marginTop: '7px' }}>
                          *** incentives 2 apply just for GROSS 1 salaries higher than &nbsp;
                          {(
                              minimumGrossValueForIncentive2 /
                              (currency === '€'
                                  ? euroDisplay
                                  : currency === '$'
                                      ? usdDisplay
                                      : currency === '£'
                                          ? gbpDisplay
                                          : currency === '₽'
                                              ? rubDisplay
                                              : 1)
                          ).toLocaleString()}
                          &nbsp; {currency}
                        </div>
                      </div>
                  )}

                  <Divider style={{ backgroundColor: '#2db1ab50', margin: '40px 0', marginBottom: '25px' }} />
                  <div className='middleExchangeRate calculator'>
                    <strong>Middle exchange rates:</strong>
                  </div>
                  {viewportWidth < 1800 && (
                      <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            border: '1px solid #ddd',
                            padding: '4px',
                            width: '40%',
                            fontSize: '12px',
                          }}
                      >
                        <div style={{ display: 'flex', border: '1px solid #ddd' }}>
                          <div
                              style={{
                                flex: 1,
                                padding: '4px',
                                border: '1px solid #ddd',
                                fontWeight: 'bold',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                paddingLeft: '10px',
                                height: '35px',
                              }}
                          >
                            Date
                          </div>
                          <div
                              style={{
                                flex: 1,
                                padding: '4px',
                                border: '1px solid #ddd',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '35px',
                              }}
                          >
                            {currentDate && <p style={{ marginTop: '11px', fontWeight: 'bold' }}>{currentDate}</p>}
                          </div>
                        </div>
                        <div style={{ display: 'flex', border: '1px solid #ddd' }}>
                          <div
                              style={{
                                flex: 1,
                                padding: '4px',
                                border: '1px solid #ddd',
                                fontWeight: 'bold',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                paddingLeft: '10px',
                                height: '35px',
                              }}
                          >
                            EUR
                          </div>
                          <div
                              style={{
                                flex: 1,
                                padding: '4px',
                                border: '1px solid #ddd',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '35px',
                              }}
                          >
                            {euroDisplay && <p style={{ marginTop: '11px' }}>{euroDisplay.toFixed(2)} RSD</p>}
                          </div>
                        </div>
                        <div style={{ display: 'flex', border: '1px solid #ddd' }}>
                          <div
                              style={{
                                flex: 1,
                                padding: '4px',
                                border: '1px solid #ddd',
                                fontWeight: 'bold',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                paddingLeft: '10px',
                                height: '35px',
                              }}
                          >
                            USD
                          </div>
                          <div
                              style={{
                                flex: 1,
                                padding: '4px',
                                border: '1px solid #ddd',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '35px',
                              }}
                          >
                            {usdDisplay && <p style={{ marginTop: '11px' }}>{usdDisplay.toFixed(2)} RSD</p>}
                          </div>
                        </div>
                        <div style={{ display: 'flex', border: '1px solid #ddd' }}>
                          <div
                              style={{
                                flex: 1,
                                padding: '4px',
                                border: '1px solid #ddd',
                                fontWeight: 'bold',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                paddingLeft: '10px',
                                height: '35px',
                              }}
                          >
                            GBP
                          </div>
                          <div
                              style={{
                                flex: 1,
                                padding: '4px',
                                border: '1px solid #ddd',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '35px',
                              }}
                          >
                            {gbpDisplay && <p style={{ marginTop: '11px' }}>{gbpDisplay.toFixed(2)} RSD</p>}
                          </div>
                        </div>
                        <div style={{ display: 'flex', border: '1px solid #ddd' }}>
                          <div
                              style={{
                                flex: 1,
                                padding: '4px',
                                border: '1px solid #ddd',
                                fontWeight: 'bold',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                paddingLeft: '10px',
                                height: '35px',
                              }}
                          >
                            RUB
                          </div>
                          <div
                              style={{
                                flex: 1,
                                padding: '4px',
                                border: '1px solid #ddd',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '35px',
                              }}
                          >
                            {rubDisplay && <p style={{ marginTop: '11px' }}>{rubDisplay.toFixed(2)} RSD</p>}
                          </div>
                        </div>
                      </div>
                  )}
                  {viewportWidth > 1800 && (
                      <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            border: '1px solid #ddd',
                            padding: '4px',
                            width: '50%',
                            fontSize: '14px',
                          }}
                      >
                        <div style={{ display: 'flex', border: '1px solid #ddd' }}>
                          <div
                              style={{
                                flex: 1,
                                padding: '4px',
                                border: '1px solid #ddd',
                                fontWeight: 'bold',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                paddingLeft: '10px',
                                height: '45px',
                              }}
                          >
                            Date
                          </div>
                          <div
                              style={{
                                flex: 1,
                                padding: '4px',
                                border: '1px solid #ddd',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '45px',
                              }}
                          >
                            {currentDate && <p style={{ marginTop: '11px', fontWeight: 'bold' }}>{currentDate}</p>}
                          </div>
                        </div>
                        <div style={{ display: 'flex', border: '1px solid #ddd' }}>
                          <div
                              style={{
                                flex: 1,
                                padding: '4px',
                                border: '1px solid #ddd',
                                fontWeight: 'bold',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                paddingLeft: '10px',
                                height: '45px',
                              }}
                          >
                            EUR
                          </div>
                          <div
                              style={{
                                flex: 1,
                                padding: '4px',
                                border: '1px solid #ddd',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '45px',
                              }}
                          >
                            {euroDisplay && <p style={{ marginTop: '11px' }}>{euroDisplay.toFixed(2)} RSD</p>}
                          </div>
                        </div>
                        <div style={{ display: 'flex', border: '1px solid #ddd' }}>
                          <div
                              style={{
                                flex: 1,
                                padding: '4px',
                                border: '1px solid #ddd',
                                fontWeight: 'bold',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                paddingLeft: '10px',
                                height: '45px',
                              }}
                          >
                            USD
                          </div>
                          <div
                              style={{
                                flex: 1,
                                padding: '4px',
                                border: '1px solid #ddd',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '45px',
                              }}
                          >
                            {usdDisplay && <p style={{ marginTop: '11px' }}>{usdDisplay.toFixed(2)} RSD</p>}
                          </div>
                        </div>
                        <div style={{ display: 'flex', border: '1px solid #ddd' }}>
                          <div
                              style={{
                                flex: 1,
                                padding: '4px',
                                border: '1px solid #ddd',
                                fontWeight: 'bold',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                paddingLeft: '10px',
                                height: '45px',
                              }}
                          >
                            GBP
                          </div>
                          <div
                              style={{
                                flex: 1,
                                padding: '4px',
                                border: '1px solid #ddd',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '45px',
                              }}
                          >
                            {gbpDisplay && <p style={{ marginTop: '11px' }}>{gbpDisplay.toFixed(2)} RSD</p>}
                          </div>
                        </div>
                        <div style={{ display: 'flex', border: '1px solid #ddd' }}>
                          <div
                              style={{
                                flex: 1,
                                padding: '4px',
                                border: '1px solid #ddd',
                                fontWeight: 'bold',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                paddingLeft: '10px',
                                height: '45px',
                              }}
                          >
                            RUB
                          </div>
                          <div
                              style={{
                                flex: 1,
                                padding: '4px',
                                border: '1px solid #ddd',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '45px',
                              }}
                          >
                            {rubDisplay && <p style={{ marginTop: '11px' }}>{rubDisplay.toFixed(2)} RSD</p>}
                          </div>
                        </div>
                      </div>
                  )}
                </Col>
                {/*vertical divider*/}
                <div
                    style={{
                      borderLeft: '1px solid #2db1ab50',
                      maxHeight: viewportHeightValue > 820 ? '1400px' : '820px',
                      margin: '0 20px',
                      width: '1px',
                    }}
                />
                <Col span={12} style={containerStyle}>
                  <div style={contentStyle} className='scroll-content calculator'>
                    <Form.Item
                        label='NET AMOUNT FOR PAYMENT'
                        className='bold-label'
                        name='neto'
                        rules={[
                          {
                            validator: (_, value) => {
                              if (calculatorType === 'net' && parseFloat(value) < netInputLowestValue) {
                                setErrorNetInput(true);
                                return Promise.reject(
                                    new Error(`Value must be greater than ${netInputLowestValue.toFixed(2)}`),
                                );
                              } else {
                                setErrorNetInput(false);
                                return Promise.resolve();
                              }
                            },
                          },
                        ]}
                    >
                      <Row gutter={8} style={{ alignItems: 'center' }}>
                        <Col span={20}>
                          <Input
                              // name='neto'
                              value={
                                calculatorType === 'net'
                                    ? neto.toLocaleString()
                                    : parseFloat(neto).toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })
                              }
                              key={randomSelectKey}
                              onChange={handleChange}
                              style={
                                calculatorType === 'net'
                                    ? { backgroundColor: '#eeeeee' }
                                    : { color: 'rgba(0, 0, 0, 0.85)', backgroundColor: '#c3e5e2' }
                              }
                              disabled={
                                calculatorType === 'net' && !incentiveCalculators
                                    ? false
                                    : !(calculatorType === 'net' && incentiveCalculators && (incentive || incentive2))
                              }
                          />
                        </Col>
                        <Col span={2}>{calculatorType === 'net' ? currency : currencyDisplay}</Col>
                      </Row>
                    </Form.Item>
                    <Form.Item label='Base for calculation of tax on salaries'>
                      <Row gutter={8} style={{ alignItems: 'center' }}>
                        <Col span={20}>
                          <Input
                              name='baseForTax'
                              value={parseFloat(baseForTax).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                              disabled
                              style={disabledStyle}
                          />
                        </Col>
                        <Col span={2}>{currencyDisplay}</Col>
                      </Row>
                    </Form.Item>
                    <Form.Item label='Tax on salaries (10%)'>
                      <Row gutter={8} style={{ alignItems: 'center' }}>
                        <Col span={20}>
                          <Input
                              name='taxSalary'
                              value={parseFloat(taxSalary).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                              disabled
                              style={disabledStyle}
                          />
                        </Col>
                        <Col span={2}>{currencyDisplay}</Col>
                      </Row>
                    </Form.Item>
                    <Row gutter={8} style={{ alignItems: 'center', justifyContent: 'right', marginTop: '-2vh' }}>
                      <Col span={20}>
                        <Form.Item colon={false}>
                        <span
                            style={{
                              textDecoration: 'underline',
                              color: '#2db1ab50',
                              fontWeight: 'bold',
                              display: 'block',
                              width: '300px',
                              marginLeft: viewportWidth > 1800 ? '15vh' : viewportHeightValue < 820 ? '12vh' : '5vh',
                              marginBottom: '-3vh',
                            }}
                        >
                          Contributions paid by employee
                        </span>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item label='Contribution for PDI (14%)'>
                      <Row gutter={8} style={{ alignItems: 'center' }}>
                        <Col span={20}>
                          <Input
                              name='contributionPDIEmployee'
                              value={parseFloat(contributionPDIEmployee).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                              disabled
                              style={disabledStyle}
                          />
                        </Col>
                        <Col span={2}>{currencyDisplay}</Col>
                      </Row>
                    </Form.Item>
                    <Form.Item label='Health insurance contribution (5.15%)'>
                      <Row gutter={8} style={{ alignItems: 'center' }}>
                        <Col span={20}>
                          <Input
                              name='healthInsuranceEmployee'
                              value={parseFloat(healthInsuranceEmployee).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                              disabled
                              style={disabledStyle}
                          />
                        </Col>
                        <Col span={2}>{currencyDisplay}</Col>
                      </Row>
                    </Form.Item>
                    <Form.Item label='Contribution on unemployment insurance (0.75%)'>
                      <Row gutter={8} style={{ alignItems: 'center' }}>
                        <Col span={20}>
                          <Input
                              name='unemploymentInsuranceEmployee'
                              value={parseFloat(unemploymentInsuranceEmployee).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                              disabled
                              style={disabledStyle}
                          />
                        </Col>
                        <Col span={2}>{currencyDisplay}</Col>
                      </Row>
                    </Form.Item>
                    <Form.Item
                        label='TOTAL GROSS 1 VALUE'
                        className='bold-label'
                        name='totalGrossAmount'
                        rules={[
                          {
                            validator: (_, value) => {
                              if (calculatorType === 'gross1' && parseFloat(value) < gross1InputLowestValue) {
                                setErrorGross1Input(true);
                                return Promise.reject(
                                    new Error(`Value must be greater than ${gross1InputLowestValue.toFixed(2)}`),
                                );
                              } else {
                                setErrorGross1Input(false);
                                return Promise.resolve();
                              }
                            },
                          },
                        ]}
                    >
                      <Row gutter={8} style={{ alignItems: 'center' }}>
                        <Col span={20}>
                          <Input
                              onChange={handleChange}
                              value={
                                calculatorType === 'gross1'
                                    ? totalGrossAmount.toLocaleString()
                                    : parseFloat(totalGrossAmount).toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })
                              }
                              style={
                                calculatorType === 'gross1'
                                    ? { backgroundColor: '#eeeeee' }
                                    : { color: 'rgba(0, 0, 0, 0.85)', backgroundColor: '#c3e5e2' }
                              }
                              disabled={
                                calculatorType === 'gross1' && !incentiveCalculators
                                    ? false
                                    : !(calculatorType === 'gross1' && incentiveCalculators && (incentive || incentive2))
                              }
                          />
                        </Col>
                        <Col span={2}>{calculatorType === 'gross1' ? currency : currencyDisplay}</Col>
                      </Row>
                    </Form.Item>
                    <Row gutter={8} style={{ alignItems: 'center', justifyContent: 'right', marginTop: '-2vh' }}>
                      <Col span={20}>
                        <Form.Item colon={false}>
                        <span
                            style={{
                              textDecoration: 'underline',
                              color: '#2db1ab50',
                              fontWeight: 'bold',
                              display: 'block',
                              width: '300px',
                              marginLeft: viewportWidth > 1800 ? '10.5vh' : '6vh',
                              marginBottom: '-3vh',
                            }}
                        >
                          Social contributions paid by employer
                        </span>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item label='Contribution for PDI (10%)'>
                      <Row gutter={8} style={{ alignItems: 'center' }}>
                        <Col span={20}>
                          <Input
                              name='contributionPDIEmployer'
                              value={parseFloat(contributionPDIEmployer).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                              disabled
                              style={disabledStyle}
                          />
                        </Col>
                        <Col span={2}>{currencyDisplay}</Col>
                      </Row>
                    </Form.Item>
                    <Form.Item label='Health insurance contribution (5.15%)'>
                      <Row gutter={8} style={{ alignItems: 'center' }}>
                        <Col span={20}>
                          <Input
                              name='healthInsuranceEmployer'
                              value={parseFloat(healthInsuranceEmployer).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                              disabled
                              style={disabledStyle}
                          />
                        </Col>
                        <Col span={2}>{currencyDisplay}</Col>
                      </Row>
                    </Form.Item>
                    <Form.Item
                        label={
                          viewportWidth > 1800 ? (
                              <div
                                  style={{
                                    whiteSpace: 'normal',
                                    wordWrap: 'break-word',
                                    textTransform: 'uppercase',
                                    marginRight: '2px',
                                    fontSize: '14px',
                                  }}
                              >
                                TOTAL GROSS 2 VALUE (TOTAL AMOUNT FOR PAYMENT)
                              </div>
                          ) : (
                              <div
                                  style={{
                                    whiteSpace: 'normal',
                                    wordWrap: 'break-word',
                                    textTransform: 'uppercase',
                                    marginRight: '2px',
                                    fontSize: '14px',
                                  }}
                              >
                                TOTAL GROSS 2 VALUE <br />
                                (TOTAL AMOUNT FOR PAYMENT)
                              </div>
                          )
                        }
                        className='bold-label'
                        name='totalAmount'
                        rules={[
                          {
                            validator: (_, value) => {
                              if (calculatorType === 'gross2' && parseFloat(value) < gross2InputLowestValue) {
                                setErrorGross2Input(true);
                                return Promise.reject(
                                    new Error(`Value must be greater than ${gross2InputLowestValue.toFixed(2)}`),
                                );
                              } else {
                                setErrorGross2Input(false);
                                return Promise.resolve();
                              }
                            },
                          },
                        ]}
                    >
                      <Row gutter={8} style={{ alignItems: 'center' }}>
                        <Col span={20}>
                          <Input
                              onChange={handleChange}
                              value={
                                calculatorType === 'gross2'
                                    ? totalAmount.toLocaleString()
                                    : parseFloat(totalAmount).toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })
                              }
                              style={
                                calculatorType === 'gross2'
                                    ? { backgroundColor: '#eeeeee' }
                                    : { color: 'rgba(0, 0, 0, 0.85)', backgroundColor: '#c3e5e2' }
                              }
                              disabled={
                                calculatorType === 'gross2'
                                    ? false
                                    : !(calculatorType === 'gross2' && incentiveCalculators && (incentive || incentive2))
                              }
                          />
                        </Col>
                        <Col span={2}>{calculatorType === 'gross2' ? currency : currencyDisplay}</Col>
                      </Row>
                    </Form.Item>
                    {incentive2 && (
                        <>
                          <Divider style={{ backgroundColor: '#2db1ab50', margin: '10px 0' }} />
                          <div
                              style={{
                                backgroundColor: '#c3e5e2',
                                border: '2px solid #2db1ab50',
                                padding: '10px 10px 5px 10px',
                              }}
                          >
                            <Form.Item label='Total payment of taxes without incentives'>
                              <Row gutter={8} style={{ alignItems: 'center' }}>
                                <Col span={20}>
                                  <Input
                                      name='taxesWithoutIncentives'
                                      value={parseFloat(taxesWithoutIncentives).toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                      disabled
                                      style={disabledStyle}
                                  />
                                </Col>
                                <Col span={2}>{currencyDisplay}</Col>
                              </Row>
                            </Form.Item>
                            <Form.Item label='Value of incentives'>
                              <Row gutter={8} style={{ alignItems: 'center' }}>
                                <Col span={20}>
                                  <Input
                                      name='valueOfIncentives1'
                                      value={parseFloat(valueOfIncentives2).toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                      disabled
                                      style={disabledStyle}
                                  />
                                </Col>
                                <Col span={2}>{currencyDisplay}</Col>
                              </Row>
                            </Form.Item>
                            <Form.Item label='TOTAL GROSS 2 VALUE (WITH INCENTIVES)' className='bold-label'>
                              <Row gutter={8} style={{ alignItems: 'center' }}>
                                <Col span={20}>
                                  <Input
                                      className='bold-label'
                                      name='totalGross2Incentives2'
                                      value={parseFloat(totalGross2Incentives2).toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                      style={disabledStyle}
                                      disabled
                                  />

                                  {/*<Input*/}
                                  {/*    className='bold-label'*/}
                                  {/*    onChange={handleChange}*/}
                                  {/*    name='totalGross2Incentives2'*/}
                                  {/*    value={calculatorType === 'gross2' && incentive2 ? totalGross2Incentives2.toLocaleString() : parseFloat(totalGross2Incentives2).toLocaleString(undefined, {*/}
                                  {/*      minimumFractionDigits: 2,*/}
                                  {/*      maximumFractionDigits: 2,*/}
                                  {/*    })}*/}
                                  {/*    style={*/}
                                  {/*      calculatorType === 'gross2'*/}
                                  {/*          ? { backgroundColor: '#eeeeee' }*/}
                                  {/*          : { color: 'rgba(0, 0, 0, 0.85)', backgroundColor: '#c3e5e2' }*/}
                                  {/*    }*/}
                                  {/*    disabled={*/}
                                  {/*      calculatorType === 'gross2' && !incentiveCalculators*/}
                                  {/*          ? false*/}
                                  {/*          : !(calculatorType === 'gross2' && incentiveCalculators && (incentive2))*/}
                                  {/*    }*/}
                                  {/*/>*/}
                                </Col>
                                <Col span={2}>{currencyDisplay}</Col>
                              </Row>
                            </Form.Item>
                          </div>
                        </>
                    )}
                    {!mealAllowance || calculatorType === 'gross2' || incentiveCalculators ? (
                        ''
                    ) : (
                        <>
                          <Divider style={{ backgroundColor: '#2db1ab50', margin: '5px 0' }} />
                          <Form.Item label='MEAL ALLOWANCE' className='bold-label' colon={false}></Form.Item>
                          <Form.Item label={calculatorType === 'net' ? 'Enter net amount' : 'Net amount'}>
                            <Row gutter={8} style={{ alignItems: 'center' }}>
                              <Col span={20}>
                                <Input
                                    onChange={handleChangeMeal}
                                    name='mealAllowanceNeto'
                                    value={
                                      calculatorType === 'net'
                                          ? mealAllowanceNeto.toLocaleString()
                                          : mealAllowanceNeto.toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })
                                    }
                                    style={
                                      calculatorType === 'net'
                                          ? { backgroundColor: '#eeeeee' }
                                          : { color: 'rgba(0, 0, 0, 0.85)', backgroundColor: '#c3e5e2' }
                                    }
                                    disabled={calculatorType === 'net' && neto ? false : true}
                                />
                              </Col>
                              <Col span={2}>{calculatorType === 'net' ? currency : currencyDisplay}</Col>
                            </Row>
                          </Form.Item>
                          <Form.Item label={calculatorType === 'gross1' ? 'Enter gross 1 amount' : 'Gross 1 amount'}>
                            <Row gutter={8} style={{ alignItems: 'center' }}>
                              <Col span={20}>
                                <Input
                                    value={
                                      calculatorType === 'gross1'
                                          ? mealAllowanceGross.toLocaleString()
                                          : parseFloat(mealAllowanceGross).toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })
                                    }
                                    name='mealAllowanceGross'
                                    onChange={handleChangeMeal}
                                    style={
                                      calculatorType === 'gross1'
                                          ? { backgroundColor: '#eeeeee' }
                                          : { color: 'rgba(0, 0, 0, 0.85)', backgroundColor: '#c3e5e2' }
                                    }
                                    disabled={calculatorType === 'gross1' && totalGrossAmount ? false : true}
                                />
                              </Col>
                              <Col span={2}>{calculatorType === 'gross1' ? currency : currencyDisplay}</Col>
                            </Row>
                          </Form.Item>
                        </>
                    )}
                    {!holidayAllowance || calculatorType === 'gross2' || incentiveCalculators ? (
                        ''
                    ) : (
                        <>
                          <Divider style={{ backgroundColor: '#2db1ab50', margin: '5px 0' }} />
                          <Form.Item label='HOLIDAY ALLOWANCE' className='bold-label' colon={false}></Form.Item>
                          <Form.Item label={calculatorType === 'net' ? 'Enter net amount' : 'Net amount'}>
                            <Row gutter={8} style={{ alignItems: 'center' }}>
                              <Col span={20}>
                                <Input
                                    onChange={handleChangeHoliday}
                                    name='holidayAllowanceNeto'
                                    value={
                                      calculatorType === 'net'
                                          ? holidayAllowanceNeto.toLocaleString()
                                          : parseFloat(holidayAllowanceNeto).toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })
                                    }
                                    style={
                                      calculatorType === 'net'
                                          ? { backgroundColor: '#eeeeee' }
                                          : { color: 'rgba(0, 0, 0, 0.85)', backgroundColor: '#c3e5e2' }
                                    }
                                    disabled={calculatorType === 'net' && neto ? false : true}
                                />
                              </Col>
                              <Col span={2}>{calculatorType === 'net' ? currency : currencyDisplay}</Col>
                            </Row>
                          </Form.Item>
                          <Form.Item label={calculatorType === 'gross1' ? 'Enter gross 1 amount' : 'Gross 1 amount'}>
                            <Row gutter={8} style={{ alignItems: 'center' }}>
                              <Col span={20}>
                                <Input
                                    name='holidayAllowanceGross'
                                    value={
                                      calculatorType === 'gross1'
                                          ? holidayAllowanceGross.toLocaleString()
                                          : parseFloat(holidayAllowanceGross).toLocaleString('de', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })
                                    }
                                    onChange={handleChangeHoliday}
                                    style={
                                      calculatorType === 'gross1'
                                          ? { backgroundColor: '#eeeeee' }
                                          : { color: 'rgba(0, 0, 0, 0.85)', backgroundColor: '#c3e5e2' }
                                    }
                                    disabled={calculatorType === 'gross1' && totalGrossAmount ? false : true}
                                />
                              </Col>
                              <Col span={2}>{calculatorType === 'gross1' ? currency : currencyDisplay}</Col>
                            </Row>
                          </Form.Item>
                        </>
                    )}
                  </div>
                </Col>
              </Row>
            </Form>
          </Card>
        </div>
      </div>
  );
}
