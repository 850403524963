import React, { useContext, useState, useEffect } from 'react';
import { Table, Input, Button, notification } from 'antd';
import { EyeOutlined, SearchOutlined, FolderAddOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import { Link } from 'react-router-dom';

const ArchiveTable = ({
  dataSource,
  columnKeys,
  recoverHandler,
  type,
  checkIncomingStatus,
  handlePaginationChange,
  paginationPurchase,
  paginationSales,
  setPaginationSales,
  setPaginationPurchase,
  handleClearFilter,
}) => {
  const user = useContext(UserContext);
  const [data, setData] = useState(dataSource);
  const [tableKey, setTableKey] = useState(0);
  const [filteredColumnsStatus, setFilteredColumnsStatus] = useState({});
  const [searchInputs, setSearchInputs] = useState({});
  let searchInput;

  useEffect(() => {
    setData(dataSource);
  }, [dataSource, searchInputs]);

  const pagination = paginationSales ? paginationSales : paginationPurchase;

  useEffect(() => {
    if (searchInputs.length || searchInput) {
      if (data && data.length && data.length && data.length !== pagination.pageSize && paginationPurchase) {
        setPaginationPurchase({
          pageSize: pagination.pageSize,
          current: pagination.current,
          total: data.length,
        });
      }

      if (data && data.length && data.length && data.length !== pagination.pageSize && paginationSales) {
        setPaginationSales({
          pageSize: pagination.pageSize,
          current: pagination.current,
          total: data.length,
        });
      }
    }
  }, [data.length, pagination.total, searchInputs, searchInput]);

  const handlePageChange = (current, filter) => {
    if (type === 'sales') {
      setPaginationSales(current);
      handlePaginationChange(current, filter);
    }

    if (type === 'purchase') {
      setPaginationPurchase(current);
      handlePaginationChange(current, filter);
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size='small'
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: () => {
      const columnFiltered = filteredColumnsStatus[dataIndex];
      return (
        <SearchOutlined
          style={{
            color: columnFiltered ? 'red' : '#bbb',
            fontSize: '1rem',
          }}
        />
      );
    },
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex) ? record[dataIndex[0]][dataIndex[1]] : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchInputs({ ...searchInputs, [dataIndex]: selectedKeys[0] || '' });
    if (selectedKeys && selectedKeys.length) {
      // Update filteredColumnsStatus for the specific column
      setFilteredColumnsStatus({
        ...filteredColumnsStatus,
        [dataIndex]: true,
      });
      // Update pagination's total count based on the filtered data
      handlePageChange(
        {
          current: 1,
          pageSize: pagination.pageSize,
        },
        { ...searchInputs, [dataIndex]: selectedKeys[0] || '' },
      );
    } else {
      // reset on initial data set for table with normal pagination
      // when empty string is in search bar of the column
      setData(dataSource);
      setFilteredColumnsStatus({
        ...filteredColumnsStatus,
        [dataIndex]: false,
      });
      setFilteredColumnsStatus({});
      setSearchInputs({});
      handlePageChange({
        current: 1,
        pageSize: pagination.pageSize,
      });
    }
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchInputs({});
    handleClearFilter(type);
  };

  const checkStatus = async (id, pagination) => {
    const status = await checkIncomingStatus(id, pagination);
    if (status) {
      const updatedData = data.map((item) => (item._id === id ? { ...item, status: status } : item));
      setData(updatedData);
      setTableKey(Math.random());

      notification.success({
        message: 'Status checked!',
        placement: 'bottomRight',
      });
    }
  };

  // define order of columns
  const desiredColumnOrder =
    type === 'sales'
      ? ['documentNumber', 'salesInvoiceId', 'supplyDate', 'customerName', 'archivedDate']
      : ['documentNumber', 'purchaseInvoiceId', 'deliveryDate', 'supplierName', 'archivedDate'];

  const columns = desiredColumnOrder.map((columnKey) => {
    const item = columnKeys?.find((item) => item.originalName === columnKey);
    if (item) {
      return {
        key: item.originalName,
        title: item?.alternativeName?.toUpperCase(),
        dataIndex: ['title', 'category'].includes(item.originalName)
          ? [item.originalName, user.language ? user.language.selected.code : '']
          : item.originalName,
        ...getColumnSearchProps(
          ['title', 'category'].includes(item.originalName)
            ? [item.originalName, user.language ? user.language.selected.code : '']
            : item.originalName,
        ),
      };
    }
    return null;
  });

  // Remove any null values (columns that didn't exist in the desired order)
  const filteredColumns = columns.filter((column) => column !== null);

  {
    type === 'purchase' &&
      filteredColumns?.push({
        title: 'Status',
        render: (text, record) => {
          return !record.status || record.status === '' ? (
            <button
              style={{ color: 'blue', textDecoration: 'underline' }}
              onClick={async () => {
                await checkStatus(record._id, pagination);
              }}
            >
              Check Status
            </button>
          ) : (
            record.status
          );
        },
      });
  }

  {
    type === 'sales' &&
      filteredColumns?.push({
        title: 'Status',
        render: (text, record) => {
          return record.status;
        },
      });
  }

  filteredColumns?.push({
    title: 'Options',
    render: (text, record) => (
      <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
        <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
          <Link to={`/admin/${record.type}-invoice-detail/${record._id}`}>
            <EyeOutlined title={`View invoice`} style={{ textDecoration: 'none', color: 'black' }} />
          </Link>
        </div>
        <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
          <FolderAddOutlined title='Recover archived invoice' onClick={() => recoverHandler(record._id, type)} />
        </div>
      </div>
    ),
  });

  return (
    <div>
      {data && (
        <Table
          size='middle'
          bordered
          dataSource={data}
          key={tableKey}
          columns={filteredColumns}
          rowKey='_id'
          pagination={{
            ...pagination,
            defaultPageSize: 20,
            position: 'bottom',
            showSizeChanger: false,
            pageSizeOptions: ['10', '20', '50', '100'],
            hideOnSinglePage: true,
            current: pagination?.current,
            pageSize: pagination?.pageSize,
            total: pagination?.total,
          }}
          onChange={(pagination, filters, sorter, extra) => {
            if (extra.action === 'paginate') {
              handlePageChange({
                current: pagination.current,
                pageSize: pagination.pageSize,
                total: pagination.total,
              });
            }
          }}
        />
      )}
    </div>
  );
};

export default ArchiveTable;
